<template>
  <div>
    <v-autocomplete
      v-model="session.selectedCasualUsers"
      :items="computedCasualUsers"
      :disabled="getIsInMiddleOfFilteingData"
      :label="$t('Guest Recipients')"
      item-value="email"
      item-text="email"
      multiple
      outlined
      dense
      chips
      small-chips
      hide-details
      :filter="customFilter"
      :search-input.sync="search"
    >
      <v-list-item
        slot="prepend-item"
        class="list-item-wrapper select-all"
        v-if="isShowSelectAll"
      >
        <v-checkbox
          v-model="isSelectAll"
          hide-details
          class="ma-0 pa-0"
          color="primary"
          :label="$t('Select all')"
        >
        </v-checkbox>
        <v-divider></v-divider>
      </v-list-item>
      <template #item="{ item, on, attrs: { disabled, inputValue, ripple } }">
        <v-list-item v-on="on" class="list-item-wrapper">
          <v-checkbox
            v-on="$listeners"
            :disabled="disabled"
            :value="inputValue"
            hide-details
            :ripple="ripple"
          >
            <template v-slot:label>
              <div class="label-wrapper">
                <v-list-item-title>{{ item.email }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.name }}
                </v-list-item-subtitle>
              </div>
            </template>
          </v-checkbox>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <span class="email-pill">
          {{ item.name }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CasualAutocomplete',
  data() {
    return {
      isSelectAll: false,

      search: '',
    }
  },
  props: {
    session: Object,
  },

  watch: {
    'session.selectedCasualUsers': {
      handler() {
        this.search = ''
      },
      deep: true,
    },
    isSelectAll: {
      handler(n) {
        if (n) {
          this.session.selectedCasualUsers = this.loggedUser.casualUsers
        } else {
          this.session.selectedCasualUsers = []
        }
      },
    },
  },

  computed: {
    ...mapGetters(['loggedUser', 'getIsInMiddleOfFilteingData']),
    computedCasualUsers() {
      return this.loggedUser.casualUsers.sort((a, b) => a.name.toLowerCase() - b.name.toLowerCase())
    },
    isShowSelectAll() {
      return this.loggedUser.casualUsers?.length > 1
    },
  },
  methods: {
    selectAllClicked() {
      this.isSelectAll = !this.isSelectAll
      if (this.isSelectAll) {
        this.session.selectedCasualUsers = this.loggedUser.casualUsers
      } else {
        this.session.selectedCasualUsers = []
      }
    },
    checkboxChanged(value, item) {
      console.log(item)
      console.log(value)
    },
    customFilter(item, queryText) {
      const email = item.email.toLowerCase()
      const name = item.name.toLowerCase()
      const query = queryText.toLowerCase()
      return email.includes(query) || name.includes(query)
    },
  },
}
</script>

<style scoped>
.email-pill {
  background-color: #e3e3e3;
  padding: 2px 5px;
  border-radius: 10px;
  margin: 2px 4px;
}

.list-item-wrapper {
  height: fit-content !important;
  cursor: pointer;
}
.list-item-wrapper:hover {
  background-color: #f6f6f6;
}

.list-item-wrapper >>> .v-input {
  width: 100%;
}
.list-item-wrapper >>> .v-input--selection-controls {
  margin: 6px !important;
}

.list-item-wrapper >>> .mdi-checkbox-marked {
  color: rgb(34, 137, 221) !important;
  caret-color: rgb(34, 137, 221) !important;
}


.select-all {
  border-bottom: 2px solid lightblue;
}

.list-item-wrapper >>> .v-input--selection-controls >>> .v-input__control >>> .v-message {
  background-color: red !important;
}
</style>
