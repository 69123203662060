<template>
  <div id="uploading-details">
    <!--POLICY-->
    <SelectPolicy
      v-if="loggedUser.organizationId === 0"
      :errors="errors"
      :session="session"
      :lightSessionRestart="lightSessionRestart"
      :type="type"
    />

    <!--ROUTE-->
    <SelectRoute
      v-if="userActiveSourceRoutes.length"
      :session="session"
      :userActiveSourceRoutes="userActiveSourceRoutes"
      :errors="errors"
      :lightSessionRestart="lightSessionRestart"
      :type="type"
    />

    <!--REGISTERED RECIPIENTS-->
    <RegisteredRecipients
      v-if="isShowSelectRgisteredRecipients"
      :session="session"
      :errors="errors"
      :lightSessionRestart="lightSessionRestart"
      :type="type"
    />

    <!--CASUAL RECIPIENTS-->
    <!-- <CasualRecipients
      v-if="loggedUser.userId && loggedUser.boundedCasuals.length"
      :session="session"
      :errors="errors"
      :lightSessionRestart="lightSessionRestart"
      :type="type"
    /> -->

    <!--SUBJECT-->
    <Subject
      v-if="loggedUser.role !== 'Individual'"
      :session="session"
      :lightSessionRestart="lightSessionRestart"
    />

    <!--MESSAGE-->
    <Message
      v-if="loggedUser.role !== 'Individual'"
      :session="session"
      :lightSessionRestart="lightSessionRestart"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Childrens
import SelectPolicy from './Childrens/SelectPolicy/SelectPolicy.vue'
import SelectRoute from './Childrens/SelectRoute/SelectRoute.vue'
import RegisteredRecipients from './Childrens/RegisteredRecipients/RegisteredRecipients.vue'
import Subject from './Childrens/Subject/Subject.vue'
import Message from './Childrens/Message/Message.vue'

export default {
  name: 'UploadingDetailsFields',
  props: {
    session: Object,
    errors: Object,
    userActiveSourceRoutes: Array,
    lightSessionRestart: Function,
    type: String,
  },

  components: {
    SelectPolicy,
    SelectRoute,
    RegisteredRecipients,
    Subject,
    Message,
  },

  computed: {
    ...mapGetters(['loggedUser']),
    isShowSelectRgisteredRecipients() {
      if (this.loggedUser.role === 'Casual') return true
      else if (this.loggedUser.organizationId === 0) return false
      else if (!this.session.selectedRoute?.routeId) return false
      return true
    },
  },
}
</script>

<style>
#uploading-details .v-list--dense .v-list-item .v-list-item__content {
  padding: 0 !important;
}

#uploading-details .v-list--dense .v-list-item .v-list-item__title {
  line-height: 2rem !important;
}
</style>
