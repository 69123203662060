<template>
  <div class="routes-list-wrapper">
    <div
      class="d-flex justify-end"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <Button
        btnType="grey"
        class="mx-2 mt-2"
        :text="$t('Cancel')"
        :clickAction="() => $router.push(cameFrom)"
      />
    </div>

    <!--TABLE ON BIG SCREENS-->
    <v-data-table
      v-if="$vuetify.breakpoint.width > 710"
      fixed-header
      :items-per-page="-1"
      :options="{ sortBy: ['id'] }"
      must-sort
      :items="computedRoutesList"
      class="wrapper-1 ma-2"
      :headers="computedRouteHeaders"
      mobile-breakpoint="200"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
      :footer-props="{
        'items-per-page-text': $t('Rows per page') + ':',
        pageText: `{0}-{1} ${$t('of')} {2}`,
        'items-per-page-all-text': $t('All'),
      }"
    >
      <template v-slot:item="{ item }">
        <tr role="row" tabindex="0">
          <td :aria-label="`ID: ${item.id}`">
            <span>{{ item.id }}</span>
          </td>
          <td :aria-label="`Route name: ${item.name}`">
            <span>{{ item.name }}</span>
          </td>
          <td :aria-label="`Senders: ${parseList(item.sources)}`">
            <span v-html="item.sources"></span>
          </td>
          <td :aria-label="`Recipients: ${parseList(item.targets)}`">
            <span v-html="item.targets"></span>
          </td></tr
      ></template>
      <template v-slot:no-data>
        <span>
          {{ loading ? 'Loading...' : 'No routes to display' }}
        </span>
      </template>
    </v-data-table>

    <!--SMALL SCREENS-->
    <div v-else class="fill-height overflow-auto">
      <RouteCard
        v-for="route of computedRoutesList"
        :key="route.id"
        :route="route"
      />
    </div>
  </div>
</template>

<script>
import { convertID } from '@/utils'
import { mapGetters, mapActions } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Component files
import { routeHeaders } from './RoutesList'
import RouteCard from './RouteCard/RouteCard.vue'

export default {
  name: 'RoutesList',
  data() {
    return {
      loading: false,
    }
  },
  props: {
    routes: Array,
    cameFrom: String,
  },

  components: { Button, RouteCard },
  computed: {
    ...mapGetters(['loggedUser', 'currentUserRoutes', 'language']),
    computedRoutesList() {
      if (!this.currentUserRoutes.length) return []

      //run on all user routes
      const routesArr = this.currentUserRoutes.map(el => {
        //if the status is not active return
        if (el.status !== 0) return

        //build the new object
        const route = {
          name: el.name,
          id: convertID(el.routeId, 'R'),
          sources: [],
          targets: [],
        }

        //run on source end points
        el.sourceEndPoints.forEach(sourceEndPoint => {
          //if the user is blocked don't show him
          if (sourceEndPoint.userStatus !== 0) return
          //if the user in the route is the logged user
          else if (sourceEndPoint.userEmail === this.loggedUser.email)
            route.sources.push(
              `<b>${this.$t('Me')}</b> <span class="fs-1r">(${
                this.loggedUser.email
              })</span>`
            )
          else route.sources.push(sourceEndPoint.userEmail)
        })

        //run on target end points
        el.targetEndPoints.forEach(targetEndPoint => {
          //if the user is blocked don't show him
          if (targetEndPoint.userStatus !== 0) return
          //if the user in the route is the logged user
          else if (targetEndPoint.userEmail === this.loggedUser.email)
            route.targets.push(
              `<b>${this.$t('Me')}</b> <span class="fs-1r">(${
                this.loggedUser.email
              })</span>`
            )
          else route.targets.push(targetEndPoint.userEmail)
        })

        route.sources = route.sources.sort().join(', ')
        route.targets = route.targets.sort().join(', ')
        return route
      })
      return routesArr.filter(el => el !== undefined)
    },
    computedRouteHeaders() {
      return routeHeaders(this)
    },
  },
  methods: {
    ...mapActions(['GET_LOGGED_USER_ROUTES']),
    parseList(str) {
      return str.replace(
        `<b>Me</b> <span class="fs-1r">(${this.loggedUser.email})</span>`,
        `Me ${this.loggedUser.email},`
      )
    },
  },
  async created() {
    try {
      this.loading = true
      await this.GET_LOGGED_USER_ROUTES()
    } catch (e) {
      console.log(e)
    } finally {
      const tableHead = document.querySelector('thead')
      const thArr = document.querySelectorAll('th')

      if (tableHead) tableHead.role = 'columnheader'

      thArr.forEach(el => {
        el.tabIndex = 0
      })

      this.loading = false
    }
  },
}
</script>

<style scoped src="./RoutesList.css"></style>
