<template>
  <v-dialog
    v-model="dialog"
    @click:outside="closeSubjectMessage"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="500"
  >
    <div
      class="wrapper-1 bg-white"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <!--TITLE-SUBJECT-->
      <v-card-title class="text-h5 bg-grey title-border">
        <b> {{ $t(interactiveInterfaceFields.subjectFieldText) }}:</b>
        <!--IF THERE IS SUBJECT-->
        <span v-if="messageDialog.subject" class="mx-2 word-break">{{
          messageDialog.subject
        }}</span>
        <!--IF THERE IS NO SUBJECT-->
        <span v-else class="f-16 mx-2"> {{ interactiveInterfaceFields.subjectFieldText === "Subject" ? $t('(No Subject)') : $t('(No Sender Details)') }}</span>
      </v-card-title>

      <!--MESSAGE - BODY-->
      <div class="pa-2 fs-1-12r">
        <b
          >{{
              interactiveInterfaceFields.messageFieldText === 'Message'
                ? $t('Message')
                : $t('Sender Details')
          }}:
        </b>
        <!--IF THERE IS MESSAGE-->
        <p v-if="messageDialog.message" class="session-message">
          {{ messageDialog.message }}
        </p>
        <!--IF THERE IS NO MESSAGE-->
        <p v-else class="f-16">{{interactiveInterfaceFields.messageFieldText === "Message" ? $t('(No message)') : $t('(No sender details)') }}</p>
      </div>

      <!--CLOSE BUTTON-->
      <v-divider></v-divider>
      <div class="d-flex justify-end pa-2">
        <Button
          :text="$t('Close')"
          btnType="grey"
          :clickAction="() => closeSubjectMessage()"
        ></Button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SubjectMessageDialog',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    closeSubjectMessage: Function,
    messageDialog: Object,
  },
  components: { Button },

  computed: {
    ...mapGetters(['isDarkMode', 'language', 'interactiveInterfaceFields']),
  },
}
</script>

<style scoped src="./SubjectMessageDialog.css"></style>
