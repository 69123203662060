<template>
  <div class="online-help-button pa-2">
    <v-icon @click="isShowDialog = true">mdi-help-circle-outline</v-icon>

    <!--DIALOG TO SHOW DATA -->
    <v-dialog
      v-model="isShowDialog"
      width="700"
      :content-class="isDarkMode ? 'dark-mode' : ''"
    >
      <div class="wrapper-1 bg-white">
        <v-card-title class="text-h5 bg-grey title-border">
          <b class="mx-auto">{{ title }}</b>
        </v-card-title>

        <div
          v-html="computedHTML"
          class="online-help-content pa-2 fs-1-25r"
        ></div>

        <v-divider></v-divider>

        <div class="d-flex justify-end pa-2">
          <Button
            text="Close"
            btnType="grey"
            :clickAction="() => (isShowDialog = false)"
          ></Button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
//Base components
import { mapGetters } from 'vuex'
import Button from '../Button/Button.vue'

//Component files
import { onlineHelpData } from './OnlineHelp'

export default {
  name: 'OnlineHelp',
  data() {
    return {
      isShowDialog: false,
      onlineHelpData,
    }
  },
  props: {
    page: String,
    section: String,
    title: String,
  },
  components: { Button },
  computed: {
    ...mapGetters(['isDarkMode', 'loggedUser']),
    computedHTML() {
      const { role } = this.loggedUser
      const { page, section } = this

      //object of super admin and host admin diffrent stuff
      if (typeof onlineHelpData[page][section] === 'object')
        return onlineHelpData[page][section][role]

      //only string for regular
      return onlineHelpData[page][section]
    },
  },
}
</script>

<style scoped src="./OnlineHelp.css"></style>
