import { render, staticRenderFns } from "./LogsHeader.vue?vue&type=template&id=136af3cb&scoped=true&"
import script from "./LogsHeader.vue?vue&type=script&lang=js&"
export * from "./LogsHeader.vue?vue&type=script&lang=js&"
import style0 from "./LogsHeader.css?vue&type=style&index=0&id=136af3cb&prod&scoped=true&lang=css&"
import style1 from "./LogsHeaderUnScoped.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136af3cb",
  null
  
)

export default component.exports