import { api } from '@/config'
import vuetify from '@/plugins/vuetify'
export default {
  state: {
    processing: false,
    notification: null,
    policies: [],
    errorNotification: '',
    isServerError: false,
    isInMiddleOfEditing: false,
    userConnectionType: '',
    accessibility: {
      mouseType: 'regular', //regular/black/white
      fontSize: 1, //1/2/3
      isStrongColors: false,
      isRegularFont: false,
      isAnimation: true,
    },
    isDarkMode: false,
    interactiveInterfaceFields: {},
  },
  mutations: {
    SET_POLICIES(state, policies) {
      state.policies = policies
    },

    SET_IS_DARKMODE(state, val) {
      //on darkmode
      if (val) {
        vuetify.framework.theme.themes.light.primary = '#0D47A1'
        state.accessibility.isStrongColors = false
      }
      //not darkmode
      else vuetify.framework.theme.themes.light.primary = '#2289DD'

      vuetify.framework.theme.isDark = val
      state.isDarkMode = val
    },
    SET_USER_CONNECTION_TYPE(state, payload) {
      state.userConnectionType = payload
    },

    SET_INTERACTIVE_INTERFACE_FIELDS_DATA(state, payload) {
      state.interactiveInterfaceFields = payload
    },
    SET_IS_IN_MIDDLE_OF_EDITING(state, payload) {
      //if the user is in middle of editing then don't let the user quit the system easly
      if (payload === true) {
        window.onbeforeunload = function (e) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
      //if the payload is false then remove function
      else {
        window.onbeforeunload = null
      }

      state.isInMiddleOfEditing = payload
    },
    SET_PROCESSING(state, payload) {
      state.processing = payload
    },

    SET_SERVER_ERROR_NOTIFICATION(state, payload) {
      state.errorNotification = payload
      state.isServerError = !state.isServerError
    },
    SET_NOTIFICATION(
      state,
      payload = { type: 'error', text: 'Error', isShowForever: false }
    ) {
      state.notification = payload
    },

    RESET_ACCESSIBILITY(state) {
      state.accessibility = {
        mouseType: 'regular', //regular/black/white
        fontSize: 1, //1/2/3
        isStrongColors: false,
        isRegularFont: false,
        isAnimation: true,
      }
    },

    SET_ACCESSIBILITY(state, payload) {
      state.accessibility = payload
    },
  },
  actions: {
    async GET_POLICIES({ commit }, payload) {
      try {
        const organizationId = payload
        const res = await api.get(
          `organizations/${organizationId ? organizationId : '0'}/policies`
        )
        if (res.status !== 200) {
          throw res
        }
        commit('SET_POLICIES', res.data)
      } catch (error) {
        console.log(error)
      }
    },

    async GET_INTERACTIVE_INTERFACE_FIELDS_DATA({ commit, getters }) {
      try {
        const orgId = getters.loggedUser.organizationId || 0
        const res = await api.get(
          `organizations/interactiveInterfaceLanguages/${orgId}`
        )
        if (res.status !== 200) throw Error
        commit('SET_INTERACTIVE_INTERFACE_FIELDS_DATA', res.data)
      } catch (error) {
        console.log(error)
      }
    },
  },
  getters: {
    isProcessing: state => state.processing,
    policies: state => state.policies,
    errorNotification: state => state.errorNotification,
    isInMiddleOfEditing: state => state.isInMiddleOfEditing,
    isServerError: state => state.isServerError,
    notification: state => state.notification,
    userConnectionType: state => state.userConnectionType,
    accessibility: state => state.accessibility,
    isDarkMode: state => state.isDarkMode,
    interactiveInterfaceFields: state => state.interactiveInterfaceFields,
  },
}
