<template>
  <div class="wrapper-2 pa-2">
    <!--INPUT FOR FILES AND BUTTON-->
    <div class="d-flex justify-space-between mb-2">
      <!--TITLE-->
      <div>
        <!-- INPUT TO ADD FILES-->
        <input
          style="display: none"
          type="file"
          ref="fileInput"
          dense
          accept=".polx"
          @change="e => importPolicy(e)"
        />
        <Button
          width="fit-content"
          btnType="blue"
          text="Create Policy"
          :clickAction="() => (showCreatePolicy = true)"
        />
        <Button
          width="fit-content"
          class="ml-4"
          btnType="grey"
          text="Import Policy"
          :clickAction="clickFileInput"
        />
      </div>
    </div>

    <!--TABLE OF THE POLICIES-->
    <v-data-table
      :items="computedPolicies"
      :headers="policiesTableHeaders"
      :hide-default-footer="true"
      class="wrapper-1"
    >
      <template v-if="computedPolicies.length" v-slot:body="{ items }">
        <tbody>
          <tr
            @mouseenter="mouseEnterTr(index)"
            @mouseleave="mouseLeaveTr(index)"
            @click="btnClicked(item)"
            v-for="(item, index) in items"
            :key="index"
            class="pointer"
          >
            <td class="font-weight-bold">
              {{ item }}
            </td>
            <!-- SHOW EDIT IF HOVER-->
            <td>
              <Button
                v-if="TrIndexHover === index"
                :clickStopAction="() => btnClicked(item)"
                text="Edit"
                width="80px"
                btnType="blue"
              />
            </td>
            <!-- SHOW RENAME IF HOVER-->
            <td>
              <Button
                v-if="TrIndexHover === index"
                :clickStopAction="() => openRenamePolicy(item)"
                text="Rename"
                btnType="grey"
                width="80"
              />
            </td>
            <!--SHOW DELETE IF HOVER-->
            <td>
              <v-tooltip top v-if="TrIndexHover === index">
                <template v-slot:activator="{ on }">
                  <v-btn
                    width="25px"
                    height="25px"
                    v-on="on"
                    @click.stop="removePolicy(item)"
                    small
                    rounded
                    fab
                    outlined
                    color="error"
                  >
                    <v-icon dark small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete policy</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <!--CREATE NEW POLICY-->
    <AddNewPolicy
      :showCreatePolicy="showCreatePolicy"
      @toggle-is-show-create-policy="val => (showCreatePolicy = val)"
    />

    <!--RENAME POLICY-->
    <RenamePolicy
      :showRenamePolicy="showRenamePolicy"
      :policyToRename="policyToRename"
      @show-rename-policy="val => (showRenamePolicy = val)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Children
import AddNewPolicy from '@/components/Policies/AddNewPolicy/AddNewPolicy.vue'
import RenamePolicy from '@/components/Policies/RenamePolicy/RenamePolicy.vue'

//Component files
import { addPolicy } from '@/actions/policies'
import { policiesTableHeaders, deletePolicy } from './Policies'
import { confirmDialog } from '@/utils'

export default {
  name: 'Policies',
  data() {
    return {
      policiesTableHeaders,
      showCreatePolicy: false,
      showRenamePolicy: false,
      policyToRename: '',
      TrIndexHover: -1,
    }
  },
  components: {
    Button,
    AddNewPolicy,
    RenamePolicy,
  },
  computed: {
    ...mapGetters(['loggedUser', 'policies']),
    computedPolicies() {
      return this.policies
    },
  },

  methods: {
    ...mapActions(['GET_POLICIES']),
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    mouseEnterTr(index) {
      this.TrIndexHover = index
    },
    mouseLeaveTr() {
      this.TrIndexHover = -1
    },
    clickFileInput() {
      this.$refs.fileInput.click()
    },
    async importPolicy(e) {
      try {
        this.SET_PROCESSING(true)
        if (!e.target.files[0].name.includes('.polx')) {
          throw new Error("Only files with '.polx' extension are allowed.")
        }
        const res = await addPolicy(
          this.loggedUser.organizationId,
          e.target.files[0]
        )
        if (res.status === 200) {
          this.SET_NOTIFICATION({
            type: 'success',
            text: 'Policy file was uploaded successfully.',
          })
          this.GET_POLICIES(this.loggedUser.organizationId)
        } else {
          throw new Error(res.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.SET_PROCESSING(false)
      }
    },

    async removePolicy(item) {
      try {
        const text = 'Are you sure you want to delete this policy?'

        const thenFunc = async () => {
          const res = await deletePolicy(this.loggedUser.organizationId, item)
          if (res.status === 200) {
            this.SET_NOTIFICATION({
              type: 'success',
              text: 'Policy was deleted successfully.',
            })
            await this.GET_POLICIES(this.loggedUser.organizationId)
          } else {
            this.SET_NOTIFICATION({
              type: 'error',
              text: 'Some problem occured. Please try again later.',
            })
          }
        }

        confirmDialog(this, text, 'Delete', 'Cancel', thenFunc)
      } catch (error) {
        console.log(error)
      }
    },
    openRenamePolicy(item) {
      this.policyToRename = item
      this.showRenamePolicy = true
    },

    btnClicked(policy) {
      this.policy = policy
      this.$router.push(`policies/${policy}`)
    },
  },
  created() {
    this.GET_POLICIES(this.loggedUser?.organizationId)
  },
}
</script>

<style scoped src="./Policies.css"></style>
