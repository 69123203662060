<template>
  <div v-if="isAuthenticated" class="casual-download-wrapper">
    <div class="casual-download-inner bg-white wrapper-2">
      <Header />

      <div class="casual-download-body pa-2 fs-1r">
        <span>{{ $t('Recieved files from') }}: {{ recivedFrom.name }}</span>

        <div v-if="subject">
          <b>{{ $t(subjectField) }}: </b> {{ subject }}
        </div>
        <div v-if="message">
          <b>{{ $t(messageField) }}: </b>{{ message }}
        </div>

        <CasualDownloadFilesTree class="mt-2" :items="items" />

        <div class="mt-6 d-flex justify-space-between align-center mx-2">
          <div class="wrapper-1 pa-2 fs-1r">
            <span>{{ $t('Size') }}: {{ totalSize }}</span>
            <span class="mx-2">|</span>
            <span>{{ $t('Total Files') }}: {{ totalFiles }}</span>
          </div>

          <Button
            :text="$t('Download')"
            width="300px"
            :clickAction="downloadClicked"
          />
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="isAuthenticated === 'not'"></div>

  <div v-else class="d-flex justify-center align-center fix-height">
    <TfaCode
      :setIsAuthenticated="setIsAuthenticated"
      :encryptedPhone="encryptedPhone"
      :recipientEmail="recipientEmail"
      :casualEmail="casualEmail"
      @set-token="setToken"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Header from '@/components/CasualInterface/CasualDownload/Header.vue'
import CasualDownloadFilesTree from '@/components/CasualInterface/CasualDownload/CasualDownloadFilesTree.vue'
import Button from '@/components/BaseComponents/Button/Button.vue'
import { bytesToSize } from '@/utils'
import { api, selectedHost } from '@/config'
import router from '@/router'
import TfaCode from '@/components/CasualInterface/TfaCode.vue'

export default {
  name: 'CasualDownload',
  data() {
    return {
      recivedFrom: {
        email: '',
        name: '',
      },
      totalSize: null,
      totalFiles: null,
      items: [],
      operationId: '',
      token: '',
      subject: null,
      message: null,
      messageField: null,
      subjectField: null,
      encryptedPhone: null,
      isAuthenticated: 'not',
      recipientEmail: null,
      casualEmail: null,
    }
  },
  computed: {
    ...mapGetters(['themeSettings']),
  },

  components: {
    Header,
    CasualDownloadFilesTree,
    TfaCode,
    Button,
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),

    async downloadClicked() {
      //make a with href and click him
      const href = `${selectedHost}/api/filter/casual/download?operationId=${this.operationId}&bearer=${this.token}`

      const a = Object.assign(document.createElement('a'), {
        href,
        style: 'display: none',
        download: `Download files`,
      })

      document.body.appendChild(a)
      a.click()
      a.remove()
    },

    setToken(token) {
      this.token = token
    },

    async setIsAuthenticated() {
      const { id } = this.$route.query
      this.operationId = id
      api.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      const response = await api.get(`casuals/casual-receiving-details/${id}`)
      if (response.status !== 200) throw Error('No data found')
      if (!response.data.isOrgHasCasuals) this.$router.push('/')
      const items = JSON.parse(response.data.items)
      this.recivedFrom.email = response.data.registeredSender.email
      this.recivedFrom.name = response.data.registeredSender.name
      this.totalSize = bytesToSize(response.data.totalSize)
      this.totalFiles = response.data.totalFiles
      this.subject = response.data.subject
      this.message = response.data.message
      this.messageField =
        response.data.interactiveInterfaceDynamicFieldNames?.messageFieldText
      this.subjectField =
        response.data.interactiveInterfaceDynamicFieldNames?.subjectFieldText
      this.items = items

      this.isAuthenticated = true
    },
  },

  async beforeMount() {
    try {
      this.SET_PROCESSING(true)
      const { key, tfa, senderEmail, casualEmail } = this.$route.query
      this.recipientEmail = senderEmail
      this.casualEmail = casualEmail
      // check if two factor auth is needed. if so, start it's prcess.
      if (tfa === 'true' || tfa === 'True') {
        this.encryptedPhone = key
        this.isAuthenticated = false
      }
      // if not, continue directly
      else {
        const { key, id } = this.$route.query
        this.operationId = id
        this.token = key
        if (!key) throw Error('No key found')
        api.defaults.headers.common['Authorization'] = `Bearer ${key}`

        this.setToken(key)
        await this.setIsAuthenticated()
      }
    } catch (e) {
      console.log(e)
      router.push('/')
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>

<style scoped lang="scss">
.casual-download-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
  width: 100%;

  .casual-download-inner {
    max-width: 550px;
    width: 50%;
  }

  @media (max-width: 768px) {
    .casual-download-inner {
      width: calc(100% - 20px);
    }
  }
}

.fix-height {
  height: calc(100% - 50px);
}
</style>
