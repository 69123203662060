<template>
  <div id="language-menu">
    <v-menu
      v-if="isShowLanguage"
      v-model="menu"
      :close-on-content-click="false"
      attach="#language-menu"
      :nudge-width="50"
      offset-y
      class="pos-relative"
    >
      <!--CLICK TO OPEN MENU -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          color="primary"
          v-bind="attrs"
          v-on="on"
          class="small-btn bg-white mx-1"
          outlined
        >
          <v-icon color="primary" dense>mdi-translate</v-icon>
        </v-btn>
      </template>

      <v-card class="pa-1 w-145">
        <h3 class="text-center">{{ $t('Language') }}</h3>
        <v-radio-group
          v-model="localLanguage"
          row
          hide-details
          :class="[localLanguage === 'he' ? 'dir-rtl' : 'dir-ltr']"
          class="mt-0 mb-2 mx-2"
        >
          <v-radio value="en" label="English" class="my-1"></v-radio>
          <v-radio value="he" label="עברית" class="my-1">
            <!-- <template v-slot:label>
              <span class="mx-1"> עברית </span>
            </template> -->
          </v-radio>
        </v-radio-group>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Language',
  data() {
    return {
      menu: false,
      localLanguage: 'en',
    }
  },

  watch: {
    localLanguage(n) {
      localStorage.setItem('dot-engines-language', n)
      this.SET_LANGUAGE(n)
    },
    language(n) {
      this.$i18n.i18next.changeLanguage(n)
      if (n === 'he' && !this.$route.params.organizationId)
        this.$vuetify.rtl = true
      else this.$vuetify.rtl = false
    },

    isShowLanguage(n) {
      if (n === false) this.localLanguage = 'en'
      this.localLanguage = this.themeSettings.language
    },
  },
  computed: {
    ...mapGetters(['themeSettings', 'language']),
    isShowLanguage() {
      if (this.$route.params.organizationId) {
        this.$vuetify.rtl = false
        return false
      } else if (this.$route.fullPath.includes('management')) {
        this.$vuetify.rtl = false
        return false
      }

      if (this.localLanguage === 'he') {
        this.$vuetify.rtl = true
      }
      return true
    },
  },
  methods: {
    ...mapMutations(['SET_LANGUAGE']),
  },

  created() {
    const storedLanguage = localStorage.getItem('dot-engines-language')
    if (storedLanguage) {
      this.localLanguage = storedLanguage
    }

    if (this.$route.query.lang) {
      this.localLanguage = this.$route.query.lang
    }

    if (!this.localLanguage) this.localLanguage = 'en'
  },
}
</script>
