var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{attrs:{"rules":_vm.isShowErrors ? _vm.subjectRules : [],"disabled":_vm.getIsInMiddleOfFilteingData,"label":_vm.interactiveInterfaceFields.subjectFieldText === 'Subject'
        ? _vm.$t('Subject')
        : _vm.$t('Request Number'),"outlined":"","dense":"","maxlength":"40","counter":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.forbiddenChars + ' ' + _vm.$t('are forbidden'))}})])]},proxy:true},{key:"counter",fn:function({ props }){return [_c('span',{staticClass:"red--text",class:[props.value < 37 && 'op-0']},[_vm._v(" "+_vm._s(` ${props.value} / ${props.max}`))])]}}]),model:{value:(_vm.session.subject),callback:function ($$v) {_vm.$set(_vm.session, "subject", $$v)},expression:"session.subject"}}),_c('v-textarea',{attrs:{"outlined":"","disabled":_vm.getIsInMiddleOfFilteingData,"label":_vm.$t(
        _vm.interactiveInterfaceFields.messageFieldText === 'Message'
          ? 'Message'
          : 'Sender Details'
      ),"rows":"3","no-resize":"","hide-details":""},model:{value:(_vm.session.message),callback:function ($$v) {_vm.$set(_vm.session, "message", $$v)},expression:"session.message"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }