<template>
  <div class="organizations-list">
    <div class="table-and-search-wrapper">
      <HeadingPanel
        class="heading-panel my-1"
        :updateFilter="updateFilter"
        @open-modal="openOrganizationsDialog"
        type="host"
        :filtersToShow="['status']"
        :updateSearch="updateSearch"
        :isFromUsers="false"
      />

      <v-data-table
        :items="computedOrganizations"
        @current-items="getFiltered"
        @update:options="optionsUpdated"
        :headers="hostTableHeaders"
        fixed-header
        :footer-props="{
          'items-per-page-options': [-1, 5, 10, 25, 50],
        }"
        :options="localOptions"
        must-sort
        :items-per-page="-1"
        :search="filter.search"
        class="wrapper-1 mx-1"
      >
        <template v-slot:item="{ item }">
          <TableRowItem
            :item="item"
            :editAction="handleOrg"
            :key="item.organizationId"
          />
        </template>
        <template v-slot:no-data>
          <p>No hosts to dispaly</p>
        </template>
      </v-data-table>
      <h2 v-if="!organizations.length" class="pa-2">No hosts yet...</h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { convertID } from '@/utils'

//Base components
import HeadingPanel from '@/components/BaseComponents/HeadingPanel/HeadingPanel.vue'
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import TableRowItem from './TableRowItem/TableRowItem.vue'

//Component files
import { hostTableHeaders } from './HostList.js'

export default {
  name: 'HostList',
  data() {
    return {
      localOptions: {},
      hostTableHeaders,
      filter: {
        search: '',
        status: 'All',
      },
    }
  },
  components: {
    TableRowItem,
    Button,
    HeadingPanel,
  },

  computed: {
    ...mapGetters(['organizations', 'isProcessing', 'loggedUser']),
    computedOrganizations() {
      let list = this.organizations.map(el => {
        const fakeID = convertID(el.organizationId, 'H')
        return { ...el, fakeID }
      })

      if (this.filter.status === 'Active') {
        return list.filter(el => el.status === 0)
      } else if (this.filter.status === 'Blocked') {
        return list.filter(el => el.status === 1)
      } else if (this.filter.status === 'Archived') {
        return list.filter(el => el.status === 2)
      } else if (this.filter.status === 'All') {
        return list
      }
      return list.filter(el => el.status !== 2)
    },
  },
  methods: {
    ...mapActions(['GET_ORGANIZATIONS', 'GET_SINGLE_ORGANIZATION']),
    ...mapMutations(['SET_PROCESSING']),
    getFiltered(e) {
      if (!e.length) this.isSearchMatch = false
      else this.isSearchMatch = true
    },
    openOrganizationsDialog() {
      this.$router.push(`/management/hosts/new`)
    },
    updateSearch(value) {
      this.filter.search = value
    },
    updateFilter(str, value) {
      this.filter.status = value
    },

    handleOrg(org) {
      this.$router.push(
        `/management/hosts/${org.organizationId}/registered/users`
      )
    },

    optionsUpdated(n) {
      if (!n.sortBy.length) return

      const sortByObj = {
        sortBy: n.sortBy[0],
        sortDesc: n.sortDesc[0],
      }

      sessionStorage.setItem(`hosts-table-headers`, JSON.stringify(sortByObj))
    },
  },
  async mounted() {
    try {
      this.SET_PROCESSING(true)
      await this.GET_ORGANIZATIONS()
      await this.GET_SINGLE_ORGANIZATION()

      const res = sessionStorage.getItem(`hosts-table-headers`)
      if (res) {
        const { sortBy, sortDesc } = JSON.parse(res)
        this.localOptions = {
          sortBy: [sortBy],
          sortDesc: [sortDesc],
        }
      } else this.localOptions = { sortBy: ['fakeID'] }
    } catch (error) {
      console.log(error)
    } finally {
      this.SET_PROCESSING(false)
    }
  },
}
</script>

<style scoped src="./HostList.css"></style>
