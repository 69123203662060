<template>
  <div class="d-flex flex-column">
    <!--INPUT TO THE FILES-->

    <div :class="computedButtonsWrapperStyle">
      <!--UPLOAD FILES-->
      <Button
        :clickAction="() => clickOnUpload('file')"
        :disabled="isInMiddleOfFiltering"
        btnType="grey"
        text="Select Files"
        width="150"
        class="my-2"
        icon="mdi-file-outline"
      />

      <!--UPLOAD FOLDERS ONLY FOR WEB-->
      <Button
        v-if="userConnectionType !== 'mobile'"
        :clickAction="() => clickOnUpload('dir')"
        :disabled="isInMiddleOfFiltering"
        btnType="grey"
        class="my-2"
        text="Select Folder"
        width="150"
        icon="mdi-folder-outline"
      />

      <!--ADD PASSWORDS-->
      <div class="mx-1 my-1 pass-btn-wrapper">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              aria-label="Insert password for protected files"
              @click="
                !isInMiddleOfFiltering ? (isShowPasswordDialog = true) : null
              "
              :class="[isInMiddleOfFiltering && 'low-op-disabeld']"
              v-on="on"
              outlined
              color="#616161"
              class="wrapper-1 bg-white small-btn"
              text
              depressed
            >
              <v-icon>mdi-lock-outline</v-icon>
            </v-btn>
          </template>
          <span> Insert password for protected files</span>
        </v-tooltip>
      </div>

      <!-- THIS IS THE INPUTS THAT GET CLICKED WHEN THE FILES BUTTON OR FOLDER BUTTON IS CLICKED-->
      <input
        @change="inputChanged"
        type="file"
        multiple
        class="d-none"
        ref="fileInput"
      />
      <input
        @change="inputChanged"
        type="file"
        class="d-none"
        webkitdirectory
        multiple
        ref="directoryInput"
      />
    </div>
    <!--ADD PASSWORD DIALOG-->
    <PasswordsDialog
      v-if="isShowPasswordDialog"
      :session="session"
      :closePasswordDialog="() => (isShowPasswordDialog = false)"
      :lightSessionRestart="lightSessionRestart"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Functions to add files
import { fileAddedFromInput } from '@/components/WebsiteInterface/uploadingFunctions'

//Childrens
import PasswordsDialog from './Childrens/PasswordsDialog/PasswordsDialog.vue'

export default {
  name: 'FileDirButtons',
  data() {
    return {
      isShowPasswordDialog: false,
    }
  },
  props: {
    session: Object,
    lightSessionRestart: Function,
  },
  components: { Button, PasswordsDialog },

  computed: {
    ...mapGetters([
      'userConnectionType',
      'isInMiddleOfFiltering',
      'accessibility',
    ]),
    computedButtonsWrapperStyle() {
      const arr = ['flex-wrap']
      //COMPLETE THIS YA ABOKANOT
      if (this.accessibility.fontSize === 1)
        arr.push('d-flex align-items-center justify-space-evenly')
      else arr.push('d-flex flex-column justify-space-between align-center')
      return arr
    },
  },

  methods: {
    clickOnUpload(type) {
      //if the user add files when the session ends
      if (this.session.operationId) this.lightSessionRestart()

      if (type === 'file') this.$refs.fileInput.click()
      else if (type === 'dir') this.$refs.directoryInput.click()
    },
    inputChanged(e) {
      fileAddedFromInput(this, e)
    },
  },
}
</script>

<style src="./FileDirButtons.css"></style>
