<template>
  <div class="py-2">
    <!--INCOMING FILES -->
    <div class="d-flex align-center my-2">
      <span
        class="light-text w-370"
        :class="[
          (organization.status !== 0 ||
            !organization.casualUsersDefinitions.isActive) &&
            'low-op-disabeld',
        ]"
        >Delete files that are sent to guest users after:</span
      >

      <!--INPUT BY NUMBER-->
      <v-text-field
        v-model="fileLifeTimeIn.fileDeleteAfterNumber"
        :disabled="
          organization.status !== 0 ||
          fileLifeTimeIn.fileDeleteAfterNumber === null
        "
        @input="fileLifeTimeInChanged"
        :error="isFileLifeTimeInError"
        class="mx-3 my-0 pa-0 mw-30 centered-input"
        height="16px"
        type="number"
        hide-details
      ></v-text-field>

      <!--SELECT WHEN-->
      <v-select
        v-model="fileLifeTimeIn.fileDeleteAfterType"
        @input="fileLifeTimeInChanged"
        :disabled="organization.status !== 0"
        :error="isFileLifeTimeInError"
        class="ma-0 pa-0 mw-80"
        height="16px"
        hide-details
        :items="computedFileLifeTimeItems"
      ></v-select>
    </div>

    <!--OUTGOING FILES-->
    <div class="d-flex align-center my-2">
      <span
        class="light-text w-370"
        :class="[
          (organization.status !== 0 ||
            !organization.casualUsersDefinitions.isActive) &&
            'low-op-disabeld',
        ]"
        >Delete files that are received from guest users after:</span
      >

      <!--INPUT BY NUMBER-->
      <v-text-field
        v-model="fileLifeTimeOut.fileDeleteAfterNumber"
        :disabled="
          organization.status !== 0 ||
          fileLifeTimeOut.fileDeleteAfterNumber === null
        "
        @input="fileLifeTimeOutChanged"
        :error="isFileLifeTimeOutError"
        class="mx-3 my-0 pa-0 mw-30 centered-input"
        height="16px"
        type="number"
        hide-details
      ></v-text-field>

      <!--SELECT WHEN-->
      <v-select
        v-model="fileLifeTimeOut.fileDeleteAfterType"
        @input="fileLifeTimeOutChanged"
        :disabled="organization.status !== 0"
        :error="isFileLifeTimeOutError"
        class="ma-0 pa-0 mw-80"
        height="16px"
        hide-details
        :items="computedFileLifeTimeItems"
      ></v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CasualFilesLifeTime',
  data() {
    return {
      isFileLifeTimeInError: false,
      isFileLifeTimeOutError: false,
      fileLifeTimeIn: {
        fileDeleteAfterNumber: 0,
        fileDeleteAfterType: 0,
      },
      fileLifeTimeOut: {
        fileDeleteAfterNumber: 0,
        fileDeleteAfterType: 0,
      },
    }
  },
  props: {
    organization: Object,
  },
  watch: {
    'fileLifeTimeIn.fileDeleteAfterType': {
      handler: function (n) {
        if (n === 2) this.fileLifeTimeIn.fileDeleteAfterNumber = null

        if (n < 2 && this.fileLifeTimeIn.fileDeleteAfterNumber === null) {
          this.fileLifeTimeIn.fileDeleteAfterNumber = 1
          this.fileLifeTimeInChanged()
        }
      },
      deep: true,
    },
    'fileLifeTimeOut.fileDeleteAfterType': {
      handler: function (n) {
        if (n === 2) this.fileLifeTimeOut.fileDeleteAfterNumber = null

        if (n < 2 && this.fileLifeTimeOut.fileDeleteAfterNumber === null) {
          this.fileLifeTimeOut.fileDeleteAfterNumber = 1
          this.fileLifeTimeInChanged()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
    computedFileLifeTimeItems() {
      return [
        { text: 'Hours', value: 0 },
        { text: 'Days', value: 1 },
        {
          text: 'Never',
          value: 2,
          disabled: this.loggedUser.role !== 'SuperAdmin',
        },
      ]
    },
  },
  methods: {
    fileLifeTimeInChanged() {
      this.isFileLifeTimeInError = false

      //if this is hours
      if (this.fileLifeTimeIn.fileDeleteAfterType === 0) {
        const hours =
          +this.fileLifeTimeIn.fileDeleteAfterNumber * 1000 * 60 * 60

        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn = hours

        if (
          !this.fileLifeTimeIn.fileDeleteAfterNumber ||
          +this.fileLifeTimeIn.fileDeleteAfterNumber > 24 ||
          +this.fileLifeTimeIn.fileDeleteAfterNumber.toString().includes('.')
        ) {
          if (this.loggedUser.role !== 'SuperAdmin')
            this.isFileLifeTimeInError = true
        }
      }

      //if this is days
      else if (this.fileLifeTimeIn.fileDeleteAfterType === 1) {
        const days =
          +this.fileLifeTimeIn.fileDeleteAfterNumber * 1000 * 60 * 60 * 24

        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn = days
        if (
          !this.fileLifeTimeIn.fileDeleteAfterNumber ||
          +this.fileLifeTimeIn.fileDeleteAfterNumber > 7 ||
          +this.fileLifeTimeIn.fileDeleteAfterNumber.toString().includes('.')
        ) {
          if (this.loggedUser.role !== 'SuperAdmin')
            this.isFileLifeTimeInError = true
        }
      }

      //NEVER
      else {
        console.log('hree')
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn = -1
      }
    },
    fileLifeTimeOutChanged() {
      this.isFileLifeTimeOutError = false
      //if this is hours
      if (this.fileLifeTimeOut.fileDeleteAfterType === 0) {
        const hours =
          +this.fileLifeTimeOut.fileDeleteAfterNumber * 1000 * 60 * 60

        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut =
          hours

        if (
          !this.fileLifeTimeOut.fileDeleteAfterNumber ||
          +this.fileLifeTimeOut.fileDeleteAfterNumber > 24 ||
          +this.fileLifeTimeOut.fileDeleteAfterNumber.toString().includes('.')
        ) {
          if (this.loggedUser.role !== 'SuperAdmin')
            this.isFileLifeTimeOutError = true
        }
      }

      //if this is days
      else if (this.fileLifeTimeOut.fileDeleteAfterType === 1) {
        const days =
          +this.fileLifeTimeOut.fileDeleteAfterNumber * 1000 * 60 * 60 * 24

        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut = days

        if (
          !this.fileLifeTimeOut.fileDeleteAfterNumber ||
          +this.fileLifeTimeOut.fileDeleteAfterNumber > 7 ||
          +this.fileLifeTimeOut.fileDeleteAfterNumber.toString().includes('.')
        ) {
          if (this.loggedUser.role !== 'SuperAdmin')
            this.isFileLifeTimeOutError = true
        }
      }

      //NEVER
      else {
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut = -1
      }
    },
  },
  mounted() {
    //if the casual files IN delete after is unlimited
    if (
      this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn ===
      9999999999999
    ) {
      this.fileLifeTimeIn.fileDeleteAfterNumber = null
      this.fileLifeTimeIn.fileDeleteAfterType = 2
    }

    //if the milisecounds IN is in days (bigger than day)
    else if (
      this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn >
      86400000
    ) {
      const days =
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn /
        24 /
        60 /
        60 /
        1000

      this.fileLifeTimeIn = {
        fileDeleteAfterNumber: days,
        fileDeleteAfterType: 1,
      }
    }

    //if the milisecounds IN is in hours
    else {
      const hours =
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsIn /
        1000 /
        60 /
        60

      this.fileLifeTimeIn = {
        fileDeleteAfterNumber: hours,
        fileDeleteAfterType: 0,
      }
    }

    //if the casual files OUT delete after is unlimited
    if (
      this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut ===
      9999999999999
    ) {
      this.fileLifeTimeOut.fileDeleteAfterNumber = null
      this.fileLifeTimeOut.fileDeleteAfterType = 2
    }

    //if the milisecounds OUT is in days (bigger than day)
    else if (
      this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut >
      86400000
    ) {
      const days =
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut /
        24 /
        60 /
        60 /
        1000

      this.fileLifeTimeOut = {
        fileDeleteAfterNumber: days,
        fileDeleteAfterType: 1,
      }
    }
    //if the milisecounds OUT is in hours
    else {
      const hours =
        this.organization.casualUsersDefinitions.deleteCasualFilesByMsOut /
        1000 /
        60 /
        60
      this.fileLifeTimeOut = {
        fileDeleteAfterNumber: hours,
        fileDeleteAfterType: 0,
      }
    }
  },
}
</script>
