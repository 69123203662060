<template>
  <div class="page-wrapper">
    <!-- <div class="half-page-wrapper"> -->
    <div class="step elevation-6">
      <div v-if="step === 1">
        <h1>Welcome to dotEngines self-filtering system</h1>
        <h2>Some more information</h2>
      </div>
      <div v-if="step === 2">
        <div v-if="getSessionData?.filesData?.length" class="files-list-wrapper">
          <SelectedFilesList :session="getSessionData" />
          <span>Total size: {{ getSessionData?.totalSize | bytesToSize }}</span>
        </div>
        <div>
          <Button v-if="getSessionData?.filesData?.length" text="Choose more files" :clickAction="chooseFilesClicked" class="mt-4" />
          
        </div>
      </div>
      <div v-if="step === 3">
        <h2>
          Choose Policy:
        </h2>
      </div>
      <NextBackButtons
        class="mt-4"
        :step="step"
        :backClicked="backClicked"
        :nextClicked="nextClicked"
        :isNextDisabled="isNextDisabled"
        :errorMessage="errorMessage"
      />
      <input
            @change="inputChanged"
            type="file"
            multiple
            class="d-none"
            ref="fileInput"
          />
    </div>
    <!-- </div> -->
    <!-- <div class="half-page-wrapper">
      <div class="files-list-wrapper">
        <SelectedFilesList
          v-if="getSessionData?.filesData?.length"
          :getSessionData="getSessionData"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Button from '@/components/BaseComponents/Button/Button.vue'
import NextBackButtons from '@/components/SelfFiltering/NextBackButtons/NextBackButtons.vue'
import SelectedFilesList from '@/components/CasualInterface/CasualUpload/SelectedFilesList.vue'
import { bytesToSize } from '@/utils'

export default {
  name: 'SelfFiltering',
  data() {
    return { step: 1,  }
  },
  computed: {
    ...mapGetters(['isDarkMode', 'getSessionData']),
    isNextDisabled() {
      if (this.step === 2 && this.getSessionData?.filesData?.length === 0) return true
      return false
    },
    errorMessage() {
      if (this.step === 2 && this.getSessionData?.filesData?.length === 0) return 'No files detected'
      return ''
    }
  },
  filters: {
    bytesToSize,
  },
  components: {
    NextBackButtons,
    Button,
    SelectedFilesList,
  },
  methods: {
    ...mapActions(['fileAddedFromInput']),
    ...mapMutations(['resetState']),
    backClicked() {
      if (this.step === 2) this.resetState()
      this.step--
    },
    nextClicked() {
      this.step++
      if (this.step === 2){
        this.chooseFilesClicked()
      }
    },
    chooseFilesClicked() {
      this.$refs.fileInput.click()
    },
    inputChanged(e) {
      console.log(e)
      this.$store.dispatch('fileAddedFromInput', e)
    },
  },
  
}
</script>

<style scoped>
.half-page-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  min-width: 550px;
  width: fit-content;
}

.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100% - 50px)
}

.files-list-wrapper {
  max-width: 90%;
  min-width: 400px;
}
</style>
