<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Guest Users</h4>
    <div class="wrapper-2 px-2 py-2 pos-relative">
      <div class="d-flex align-center" >
<div @mouseover="showGuestsMessage = true" @mouseleave="showGuestsMessage = false">

  <v-checkbox
  v-model="profile.isAllowCasuals"
  
  :disabled="
          isFieldDisabled ||
          (!freezedProfile.isAllowCasuals && casualLicense.numOfInUse + 1 > casualLicense.numOfAllowed)
          "
        label="License to interact with guests"
        class="mb-2"
        hide-details
        dense
        />
      </div>
        <span v-if="showGuestsMessage && !freezedProfile.isAllowCasuals && casualLicense.numOfInUse + 1 > casualLicense.numOfAllowed" class="red--text ml-4">Not enough licenses</span>
      </div>
      <!-- <span class="fs-1r"
        >Usage:
        {{
          freezedProfile.isAllowCasuals !== profile.isAllowCasuals
            ? casualLicense.numOfInUse + 1
            : casualLicense.numOfInUse
        }}
        / {{ casualLicense.numOfAllowed }}</span
      > -->

      <OnlineHelp page="singleUser" section="casual" title="Guest Users" />
      <CasualGroups
        v-if="profile.license === 'SFTP' && profile.isAllowCasuals && profile.casualUsers.length > 0"
        :user="profile"
        :parent="'admin'"
        @setGroup="setGroup"
        :setIsTarget="v => (isTarget = v)"
      />
      <SftpDetailsDialogForCasual
        v-if="isShowSftpDetailsDialog"
        :closeDialog="() => (isShowSftpDetailsDialog = false)"
        :isTarget="isTarget"
        :userEmail="profile.email"
        :groupName="group.name"
        :groupMembers="group.casualUsers"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import CasualGroups from '@/components/Profile/GuestsSFTP/CasualGroups.vue'
import SftpDetailsDialogForCasual from './Children/SftpDetailsDialogForCasual/SftpDetailsDialogForCasual.vue'
import { mapGetters, mapMutations } from 'vuex'

//Components files
import { regularUserCasualUsersTableHeaders } from './CasualUsers'
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'

export default {
  name: 'CasualUsers',
  data() {
    return {
      regularUserCasualUsersTableHeaders,
      isNumOfAllowedError: false,
      isCasualUsersTableOpen: false,
      isShowSftpDetailsDialog: false,
      isTarget: false,
      group: null,
      showGuestsMessage: false,
    }
  },
  props: {
    profile: Object,
    isFieldDisabled: Boolean,
    casualLicense: Object,
    freezedProfile: Object,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
  },
  methods: {
    ...mapMutations(['SET_PROCESSING']),
    setIsShowSftpDetailsDialog(isTarget) {
      this.isShowSftpDetailsDialog = true
      this.isTarget = isTarget
    },
    setGroup(group){
      this.group = group
      this.isShowSftpDetailsDialog = true
    }
  },
  components: { Button, OnlineHelp, CasualGroups, SftpDetailsDialogForCasual },
}
</script>
