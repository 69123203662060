<template>
  <div class="pa-2 mb-2">
    <h4 class="settings-h4">Notifications</h4>
    <div class="wrapper-2 py-1 px-3 pos-relative">
      <div class="d-flex py-0">
        <span class="send-notifications-title"></span>
        <span class="light-text ml-3"> Send Notification Via: </span>
      </div>
      <!-- WHEN THERE ARE FILES TO DOWNLOAD-->
      <div v-if="isShowEmailMessages" class="d-flex py-0">
        <span class="radio-group-label mr-2 mb-0">
          Files for downloading are awaiting
        </span>
        <v-radio-group
          v-model="profile.sendingMethodForAwaitingFiles"
          :disabled="isFieldDisabled"
          row
          hide-details
          class="ma-0"
        >
          <v-radio :value="1" label="Email"></v-radio>
          <!-- <v-radio :value="2" label="SMS" :disabled="isSmsDisabled"></v-radio> -->
          <v-radio :value="0" label="None"></v-radio>
        </v-radio-group>
      </div>

      <!-- IF THE USER WANT TO GET AN ALERT TWO DAYS BEFORE THEY DELETE-->
      <div v-if="isShowEmailMessages" class="d-flex py-0">
        <span class="radio-group-label mr-2 mb-0">
          User's files are about to be deleted
        </span>
        <v-radio-group
          v-model="profile.sendingMethodForFilesAboutToDelete"
          :disabled="isFieldDisabled"
          row
          hide-details
          class="ma-0"
        >
          <v-radio :value="1" label="Email"></v-radio>
          <!-- <v-radio :value="2" label="SMS" :disabled="isSmsDisabled"></v-radio> -->
          <v-radio :value="0" label="None"></v-radio>
        </v-radio-group>
      </div>

      <!-- WHEN USER HIMSELF ASK FOR A PASSWORD RESET-->

      <div class="d-flex py-0">
        <span class="radio-group-label mr-2 mb-0">
          Password reset is asked by the user
        </span>
        <v-radio-group
          v-model="profile.sendingMethodForSelfResetPassword"
          :disabled="isFieldDisabled"
          row
          hide-details
          class="ma-0"
        >
          <v-radio :value="1" label="Email"></v-radio>
          <!-- <v-radio :value="2" label="SMS" :disabled="isSmsDisabled"></v-radio> -->
        </v-radio-group>
      </div>

      <!-- WHEN ADMIN ASK FOR A PASSORD RESET -->
      <div
        v-if="profile.role !== 'Individual' && profile.role !== 'SuperAdmin'"
        class="d-flex py-0"
      >
        <span class="radio-group-label mr-2 mb-0">
          Password reset is asked by the host admin
        </span>

        <v-radio-group
          v-model="profile.sendingMethodForAdminResetPassword"
          :disabled="isFieldDisabled"
          row
          hide-details
          class="ma-0"
        >
          <v-radio :value="1" label="Email"></v-radio>
          <!-- <v-radio :value="2" label="SMS" :disabled="isSmsDisabled"></v-radio> -->
        </v-radio-group>
      </div>

      <div
        v-if="profile.role !== 'Individual' && profile.role !== 'SuperAdmin'"
        class="d-flex py-0"
      >
        <span class="radio-group-label mr-2 mb-0">
          Files were sent to guest
        </span>

        <v-radio-group
          v-model="profile.sendingMethodForFilesWereSentToCasuals"
          :disabled="isFieldDisabled"
          row
          hide-details
          class="ma-0"
        >
          <v-radio :value="1" label="Email"></v-radio>
          <!-- <v-radio :value="2" label="SMS" :disabled="isSmsDisabled"></v-radio> -->
          <v-radio :value="0" label="None"></v-radio>
        </v-radio-group>
      </div>

      <!-- WILL SHOWN ONLY IF TWO VERFICATION IN WORKING -->
      <div v-if="profile.isTwoStepVerification" class="d-flex py-0">
        <span class="radio-group-label mr-2 mb-0">
          Two-Factor Authentication is needed
        </span>
        <v-radio-group
          v-model="profile.sendingMethodForTwoStepVerificationCode"
          :disabled="isFieldDisabled"
          row
          hide-details
          class="ma-0"
        >
          <v-radio :value="1" label="Email"></v-radio>
          <v-radio :value="2" label="SMS" :disabled="isSmsDisabled"></v-radio>
        </v-radio-group>
      </div>
      <OnlineHelp
        page="singleUser"
        section="notifications"
        title="Notifications"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//Base components
import OnlineHelp from '@/components/BaseComponents/OnlineHelp/OnlineHelp.vue'
export default {
  name: 'UserCommunications',
  props: {
    profile: { type: Object, default: {} },
    accountType: String,
    actionType: String,
    isFieldDisabled: Boolean,
  },
  computed: {
    ...mapGetters(['currentOrganization']),
    isSmsDisabled() {
      //if there is phone number and he is not valid
      if (
        !this.profile.phoneNumber &&
        (this.profile.phoneNumber[0] !== '+' ||
          this.profile.phoneNumber.length < 7)
      )
        return true

      //if the org dosent have sms vendor
      // if (this.currentOrganization.smsDefinitions.smsVendorEnum === 10)
      //   return true
      return false
    },
    isShowEmailMessages() {
      if (this.profile.role === 'SuperAdmin') return false
      else if (this.profile.role === 'Individual') return false
      else if (this.profile.license === 'API') return false
      return true
    },
  },
  components: { OnlineHelp },
}
</script>

<style scoped src="./UserCommunications.css"></style>
