<template>
  <div :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']">
    <!--TITLE-->
    <v-stepper-step :complete="step > 1" step="1" class="pa-2" tabindex="0">
      <!-- {{ $t('Details') }} -->
    </v-stepper-step>

    <!--SELECT FIELDS-->
    <v-stepper-content step="1" class="pa-0 ma-0">
      <div class="wrapper-1 my-2 mx-1 pa-2">
        <!--UPLOADING DETAILS FIELDS-->
        <v-tabs v-model="activeTab.tab" class="px-2 pb-2">
          <div v-if="isShowTabs" class="mx-auto d-flex">
            <v-tab href="#regular">{{ $t('Registered') }}</v-tab>
            <v-tab href="#casual">{{ $t('Guest') }}</v-tab>
          </div>

          <v-tab-item value="regular" class="mt-2">
            <UploadingDetailsFields
              :session="session"
              :errors="errors"
              :userActiveSourceRoutes="userActiveSourceRoutes"
              :lightSessionRestart="() => null"
              type="mobile"
            />
          </v-tab-item>

          <v-tab-item value="casual" class="mt-2">
            <CasualSend :session="session" />
          </v-tab-item>
        </v-tabs>

        <!--CONTINUE BUTTON-->
        <div class="d-flex justify-end mt-2" @click="continueButtonClicked">
          <Button
            :text="$t('Continue')"
            :disabled="computedIsDisabled"
            :clickAction="continueButtonClicked"
          />
        </div>
      </div>
    </v-stepper-content>
  </div>
</template>

<script>
//Functions to check if upload is okay
import { checkUploadingDetails } from '@/components/WebsiteInterface/Websiteinterface'
import CasualSend from '@/components/WebsiteInterface/CommonComponents/CasualSend/CasualSend.vue'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import UploadingDetailsFields from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/UploadingDetailsFields.vue'
import { mapGetters } from 'vuex'
const startString = `{"policy":"","selectedUsers":[],"selectedCasualUsers":[],"selectedRoute":"","subject":"","message":"","filesData":[],"totalSize":0,"htmlReport":{"summary":"","date":""},"passwords":"","sizeOfUploadedData":0,"operationId":""}`
export default {
  name: 'UploadingDetails',
  data() {
    return {
      errors: {
        isPolicyError: false,
        isRouteError: false,
        isRegError: false,
        isCasError: false,
        isSubjectError: false,
      },
      isShowUploadingDetails: true,
      filteringErrMessage: 'a',
    }
  },
  props: {
    step: Number,
    session: Object,
    userActiveSourceRoutes: Array,
    setStep: Function,
    activeTab: Object,
  },
  components: {
    Button,
    UploadingDetailsFields,
    CasualSend,
  },

  watch: {
    errors: {
      handler: function (n) {
        //this will restart the errors after 2.5 sec
        for (let err of Object.keys(this.errors)) {
          if (n[err]) setTimeout(() => (this.errors[err] = false), 2500)
        }
      },
      deep: true,
    },
    session: {
      handler: function (n) {
        if (JSON.stringify(n) === startString)
          return (this.filteringErrMessage = 'a')

        const res = checkUploadingDetails(this, 'mobile', true)
        if (res === true) this.filteringErrMessage = ''
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['language', 'interactiveInterfaceFields', 'loggedUser']),
    computedIsDisabled() {
      return !!this.filteringErrMessage
    },

    isShowTabs() {
      const isShowCasuals = this.loggedUser.isAllowCasuals
      const isShowRegistererd = this.userActiveSourceRoutes.length > 0
      if (!isShowCasuals || !isShowRegistererd) {
        const el = document.getElementsByClassName('v-slide-group')
        if (el && el.length && el[1]) el[1].style.display = 'none'
        return false
      } else {
        const el = document.getElementsByClassName('v-slide-group')
        if (el && el.length && el[1]) el[1].style.display = ''
      }

      return true
    },
  },
  methods: {
    continueButtonClicked() {
      const res = checkUploadingDetails(this, 'mobile')
      if (res === true) {
        this.errors = {
          isPolicyError: false,
          isRouteError: false,
          isRegError: false,
          isCasError: false,
          isSubjectError: false,
        }
        this.setStep(2)
        this.$forceUpdate()
      }
    },
  },
}
</script>
