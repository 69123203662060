<template>
  <div class="downloading-tab">down</div>
</template>

<script>
export default {
  name: 'Downloading',
}
</script>

<style scoped>
.downloading-tab {
  border: 1px solid red;
  width: 100%;
  height: 100%;
}
</style>
