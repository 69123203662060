<template>
  <div
    class="d-flex table-header bg-grey fs-1-25r"
    :class="[type === 'tablet' && 'table-header-tablet']"
  >
    <div
      v-for="cell of computedHeaders"
      @click="sortByClicked(cell, true)"
      :key="cell.title"
      :class="['th fill-height mx-1', cell.class, cell.sortable && 'pointer']"
      :style="{ width: `${cell.width}%` }"
      tabindex="0"
      role="columnheader"
      :aria-label="cell.title"
      :ref="cell.name"
    >
      <!--IF THE CELL IS THE CHECKBOX-->
      <div
        v-if="cell.title === 'checkbox'"
        class="d-flex justify-center aign-center"
      >
        <v-checkbox
          :value="isAllDirFilesSelected"
          @change="selectAllFilesChanged"
          aria-label="Select all files and directories in this directory"
          hide-details
          class="ma-0 pa-0"
          :class="[type !== 'desktop' && 'touch-screen-checkbox']"
        ></v-checkbox>
      </div>

      <span v-else>
        {{ cell.title }}
      </span>

      <v-icon v-if="sortBy === cell.name" dense
        >mdi-arrow-{{ sortType === 'inc' ? 'up' : 'down' }}</v-icon
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  desktopTableHeaders,
  tabletTableHeaders,
  mobileTableHeaders,
} from '../../DirectoryDownloadTable'

export default {
  name: 'TableHeader',
  data() {
    return {
      sortType: 'dec',
      sortBy: 'dateCreated',
    }
  },
  props: {
    computedFileList: Array,
    sortByChanged: Function,
    setSelectedDirFiles: Function,
    setNameTitleWidth: Function,
    isAllDirFilesSelected: Boolean,
    type: String,
  },
  computed: {
    ...mapGetters(['loggedUser', 'interactiveInterfaceFields']),
    computedHeaders() {
      //desktop
      if (this.type === 'desktop') return desktopTableHeaders(this)
      //tablet
      else if (this.type === 'tablet') return tabletTableHeaders(this)
      else if (this.type === 'mobile') return mobileTableHeaders(this)
    },
  },
  methods: {
    selectAllFilesChanged(val) {
      //when the user clicked select all file
      if (val) this.setSelectedDirFiles(this.computedFileList)
      //when the user de-select all files
      else this.setSelectedDirFiles([])
    },

    sortByClicked(head) {
      //if the head is not sortable
      if (!head.sortable) return

      //switch the data
      if (this.sortType === 'inc') this.sortType = 'dec'
      else if (this.sortType === 'dec') this.sortType = 'inc'

      //set sort by and how
      this.sortBy = head.name
      this.sortByChanged(head.name, this.sortType)
    },
  },

  created() {
    if (this.type !== 'desktop') {
      this.sortType = 'inc'
      this.sortBy = 'name'
      this.$forceUpdate()
    }
  },
  mounted() {
    // this.setNameTitleWidth(this.$refs.Name[0].offsetWidth)
  },
}
</script>

<style scoped src="./TableHeader.css"></style>

<style src="./TableHeaderUnScoped.css"></style>
