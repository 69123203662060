import { api, selectedHost } from '@/config'
import store from '@/store'
import * as tus from 'tus-js-client'
import {
  alertDialog,
  confirmDialog,
  generateLocalDate,
  generateNewName,
  parseIcon,
} from '@/utils'
import dayjs from 'dayjs'
//========

export const startObjForUpload = {
  //=====BEFORE SESSION=====
  session: {
    policy: '',
    selectedUsers: [],
    selectedCasualUsers: [],
    selectedRoute: '',
    subject: '',
    message: '',
    filesData: [],
    totalSize: 0,
    htmlReport: {
      summary: '',
      date: '',
    },
    passwords: '',
    sizeOfUploadedData: 0,
    operationId: '',
  },
  filteringErrMessage: '',
  freezedTotalSize: 0,
  startTime: '',
  errors: {
    isPolicyError: false,
    isRouteError: false,
    isRegError: false,
    isCasError: false,
    isSubjectError: false,
  },
  isDrag: false,

  //=====THROGH SESSION=====
  upload: null,
  timer: 0,
  curFileUploaded: null,
  sessionFiles: {
    total: 0,
    progress: 0,
  },
  sessionSize: {
    total: 0,
    progress: 0,
  },
  filteringProgress: {
    dataSent: 0,
    analyzedPercentage: 0,
    synthesizedPercentage: 0,
  },

  //=====AFTER SESSION=====
  statusResults: {
    intact: 0,
    modified: 0,
    rejected: 0,
    total: 0,
    session: {
      htmlReport: {
        summary: '',
        date: '',
      },
    },
    partial: 0,
  },
}

export function checkUploadingDetails(vue, type, isCheckOnly) {
  //this function check if uploading on tablet and mobile are fine
  //it gets the component as "this" and the type of the connection
  //if some field is not okay it returns
  //if everything is okay it returnes "true"

  const { loggedUser } = store.getters
  vue.errors.isPolicyError = false
  vue.errors.isRouteError = false
  vue.errors.isRegError = false
  vue.errors.isCasError = false
  vue.errors.isSubjectError = false

  //====================
  //if this is super admin or individual
  //====================
  if (loggedUser.organizationId === 0) {
    //if there is no policy
    if (!vue.session.policy) {
      if (!isCheckOnly) vue.errors.isPolicyError = true
      vue.filteringErrMessage = vue.$t('Policy is required')
      if (type !== 'desktop' && !isCheckOnly)
        alertDialog(vue, vue.filteringErrMessage)
      return
    }
  }

  //====================
  //if the user is casual
  //====================
  else if (loggedUser.role === 'Casual') {
    //if no users selected
    if (!vue.session.selectedUsers.length) {
      if (!isCheckOnly) vue.errors.isRegError = true
      vue.filteringErrMessage = vue.$t(
        'At least one registered recipient is required'
      )
      if (type !== 'desktop' && !isCheckOnly)
        alertDialog(vue, vue.filteringErrMessage)

      return
    }

    //if there is no subject
    else if (!vue.session.subject) {
      if (!isCheckOnly) vue.errors.isSubjectError = true

      if (vue.interactiveInterfaceFields.subjectFieldText === 'Subject')
        vue.filteringErrMessage = vue.$t('Subject is invalid')
      else vue.filteringErrMessage = vue.$t('Request number is invalid')

      if (type !== 'desktop' && !isCheckOnly)
        alertDialog(vue, vue.filteringErrMessage)

      return
    }
  }

  //=======================================
  //if the user is registered / host admin
  //=======================================
  else {
    //if there is route and there are no users selected
    if (
      vue.session.selectedRoute?.routeId &&
      !vue.session.selectedUsers.length
    ) {
      if (!isCheckOnly) vue.errors.isRegError = true
      vue.filteringErrMessage = vue.$t(
        'At least one registered recipient is required'
      )
      if (type !== 'desktop' && !isCheckOnly)
        alertDialog(vue, vue.filteringErrMessage)

      return
    }

    //if there are no users casual or registered
    if (
      !vue.session.selectedUsers.length &&
      !vue.session.selectedCasualUsers.length
    ) {
      if (!isCheckOnly) {
        vue.errors.isCasError = true
        vue.errors.isRegError = true
      }
      //if the organization have casuals
      if (loggedUser.isOrgHasCasuals)
        vue.filteringErrMessage = vue.$t(
          'At least one registered or casual recipient is required'
        )
      //if the organization don't have casuals
      else
        vue.filteringErrMessage = vue.$t(
          'At least one registered recipient is required'
        )

      if (type !== 'desktop' && !isCheckOnly)
        alertDialog(vue, vue.filteringErrMessage)

      return
    }
  }

  //====================
  //cases for all users
  //====================
  //if there is subject and it's not valid
  if (vue.session.subject && !isFolderNameValid(vue.session.subject)) {
    if (!isCheckOnly) vue.errors.isSubjectError = true

    if (vue.interactiveInterfaceFields.subjectFieldText === 'Subject')
      vue.filteringErrMessage = vue.$t('Subject is invalid')
    else vue.filteringErrMessage = vue.$t('Request number is invalid')

    if (type !== 'desktop' && !isCheckOnly)
      alertDialog(vue, vue.filteringErrMessage)

    return
  }
  //if there are no files added
  if (vue.session.filesData.length === 0) {
    //on tablet alert a message
    if (type === 'tablet') {
      vue.filteringErrMessage = vue.$t('Files for filtering are missing')

      if (!isCheckOnly) return alertDialog(vue, vue.filteringErrMessage)

      return
    }

    //on desktop show a message
    if (type === 'desktop')
      return (vue.filteringErrMessage = vue.$t(
        'Files for filtering are missing'
      ))
  }

  //=====================
  //if everything is okay
  //=====================

  //on mobile
  if (type === 'mobile') return true
  //on tablet
  if (type === 'tablet') return true
  //on desktop
  if (type === 'desktop') return (vue.filteringErrMessage = '')
}

export async function startFiltering(vue, connectionType) {
  //this function runs when user start filtering
  store.commit('SET_NOTIFICATION', null)
  try {
    const { totalSize } = vue.session
    const { loggedUser } = store.getters
    vue.timer = 0
    //this is to show the progress
    vue.sessionFiles.total = vue.session.filesData.length
    vue.sessionSize.total = totalSize

    //to end of the session
    vue.session.freezedTotalSize = totalSize
    vue.session.startTime = generateLocalDate(new Date())

    store.commit('SET_IS_IN_MIDDLE_OF_FILTERING', true)

    const body = {
      route: vue.session.selectedRoute?.name || null,
      policy: vue.session.selectedRoute?.policy || vue.session.policy,
      passwords: vue.session.passwords,
      subject: vue.session.subject,
      message: vue.session.message,
      emails: [
        ...vue.session.selectedUsers,
        ...vue.session.selectedCasualUsers,
      ],
      totalFiles: vue.session.filesData.length,
      license: 0,
    }

    //this will get the operation id
    const res = await api.post('filter', body)
    if (res.status > 204) throw Error //res need to be 201
    const operationId = res.data
    vue.session.operationId = operationId

    //open new event source with the server
    vue.evtSource = new EventSource(
      `${selectedHost}/api/Filter/progress?OperationId=${operationId}`
    )

    vue.evtSource.onmessage = function (event) {
      const values = event.data.split(',')

      //this is for the progress of the files
      vue.filteringProgress.dataSent = ((values[0] * 100) / totalSize).toFixed(
        2
      )
      vue.filteringProgress.analyzedPercentage = (
        (values[1] * 100) /
        totalSize
      ).toFixed(2)
      vue.filteringProgress.synthesizedPercentage = (
        (values[2] * 100) /
        totalSize
      ).toFixed(2)
    }

    //======= FINISHED - GET REPORT =======
    //when the filering was finished
    vue.evtSource.addEventListener(
      'Completed',
      async function (e) {
        //e.data represent how much files sent
        if (+e.data !== vue.sessionFiles.total) return
        //close the event source
        vue.evtSource.close()
        vue.curFileUploaded = null
        vue.upload = null

        const summary = await api.get(
          `/Filter/summary?OperationId=${operationId}`
        )
        if (summary.status !== 200) throw Error
        //get from the server the html report if the logged user can see it
        if (loggedUser.role !== 'Casual' || loggedUser.isAllowReport)
          vue.session.htmlReport = summary.data

        const statusResults = [
          'intact',
          'modified',
          'partial',
          'rejected',
          'total',
        ]
        for (const key of statusResults) {
          vue.statusResults[key] = summary.headers[`${key}-files`]
        }

        //save the session results
        vue.statusResults.session = JSON.parse(JSON.stringify(vue.session))

        //clear the operation id
        const clearOpIdRes = await api.delete(
          `filter?operationId=${operationId}`
        )
        if (clearOpIdRes.status !== 200) throw Error

        clearInterval(vue.interval)
        store.commit('SET_IS_IN_MIDDLE_OF_FILTERING', false)
        vue.filteringProgress.dataSent = 100
        vue.filteringProgress.synthesizedPercentage = 100
        vue.filteringProgress.analyzedPercentage = 100

        //if the connection is from mobile
        if (connectionType === 'mobile') vue.step = 4
      },
      false
    )

    //when there is an error
    vue.evtSource.addEventListener(
      'Error',
      async function (e) {
        vue.evtSource.close()
        vue.session.filesData = []
        //clear the operation id
        await api.delete(`filter?operationId=${operationId}`)

        alertDialog(vue, decodeURIComponent(e.data))

        //resart the session
        vue.restartSessionClicked()
      },
      false
    )

    //interval to count the time
    vue.interval = setInterval(() => (vue.timer += 1000), 1000)

    const duplicateFilesData = vue.session.filesData.slice()

    for (const file of duplicateFilesData) {
      vue.curFileUploaded = file
      await sendFileData(file, vue, operationId)
      vue.sessionFiles.progress++
      vue.sessionSize.progress += file.file.numbSize
    }

    const setOpIdRes = await api.put(`filter?operationId=${operationId}`)
    if (setOpIdRes.status !== 200) throw Error
  } catch (error) {
    console.log(error)
    clearInterval(vue.interval)
    store.commit('SET_IS_IN_MIDDLE_OF_FILTERING', false)
    vue.session.totalSize = 0
    vue.session.filesData = []
  } finally {
    vue.session.sizeOfUploadedData = 0
  }
}

//====SEND ONE FILE TO THE SERVER====//
async function sendFileData(file, vue, operationId) {
  const { loggedUser } = store.getters

  return new Promise((resolve, reject) => {
    try {
      if (!file) return

      const headers = {
        OperationId: operationId,
        authorization: `Bearer ${loggedUser.token}`,
      }

      //the object to the tus upload file connection
      const uploadObj = {
        endpoint: `${selectedHost}/api/Filter/file`,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        headers,
        metadata: {
          filename: file.file.name,
          filetype: file.data.type,
          filepath: file.file.path,
        },

        //when there is an tus error
        onError: async function (error) {
          console.log(error)
          vue.evtSource
            .close()
            .toString()
            .split('response text: ')
            .pop()
            .split(',')[0]
          store.commit('SET_NOTIFICATION', { type: 'error', text: error })
          vue.session.filesData = []
          vue.session.totalSize = 0

          //clear the operation id
          await api.delete(`filter?operationId=${operationId}`)
        },

        //when the file was successfully uploaded
        onSuccess: function () {
          deleteItem(file, vue)
          resolve(true)
        },
      }

      //on the tus progress
      uploadObj.onProgress = function (bytesUploaded, bytesTotal) {
        vue.curFileUploaded.file.progressPrecent = Math.round(
          (bytesUploaded / bytesTotal) * 100
        )
        vue.curFileUploaded.file.progress = bytesUploaded
        const evt = new CustomEvent('progressEvent')
        window.dispatchEvent(evt)
      }

      //this is create the connection for the file
      vue.upload = new tus.Upload(file.data, uploadObj)

      vue.upload.start()
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

//================================
//======ADD FILES FUNCTIONS=======
//================================
export function fileAddedFromInput(vue, e) {
  //the input for the files "clicked"
  const files = Array.from(e.target.files)
  if (!files) return
  files.forEach(file => {
    const path = file.webkitRelativePath.slice(
      0,
      file.webkitRelativePath.lastIndexOf('/') + 1
    )
    addFileItem(vue, file, path)
  })
}

async function addFileItem(vue, f, path) {
  //this function adds files to the session object
  const fileObj = {
    file: {
      name: f.name.replaceAll(/[\/\\:*?"<>]/g, ''),
      size: Math.ceil(f.size / 1024),
      numbSize: f.size,
      progress: 0,
      progressPrecent: 0,
      icon: parseIcon(f.name),
      lastModifiedDate: dayjs(f.lastModified).format('YYYY-MM-DD HH-mm'),
      path,
      isMoreDetails: false,
    },
    data: f,
  }

  // this check if the file have uniqe name and if so it adds (x)
  if (
    vue.session.filesData.some(
      el =>
        el.file.name === fileObj.file.name && el.file.path === fileObj.file.path
    )
  ) {
    fileObj.file.name = generateNewName(
      fileObj.file.name,
      vue.session.filesData
    )
  }

  vue.session.totalSize += fileObj.data.size
  vue.session.filesData.push(fileObj)
}

export function addDroppedFileOrDirectory(vue, e) {
  //when file droppes
  vue.isDrag = false
  if (store.getters.isInMiddleOfFiltering) return
  const droppedFiles = e.dataTransfer?.items
  if (!droppedFiles) return

  // iterate through the dropped files and call the traverseFileTree function for each file
  for (let file of droppedFiles) {
    traverseFileTree(vue, file.webkitGetAsEntry())
  }
}

function traverseFileTree(vue, item, path = '') {
  //This function is a recursive function that iterates through all the files/folders and build thier path
  if (item.isFile) {
    item.file(file => addFileItem(vue, file, path))
  }
  // create a directory reader object and read the entries
  else if (item.isDirectory) {
    item.createReader().readEntries(entries => {
      for (let entry of entries) {
        traverseFileTree(vue, entry, `${path}${item.name}/`)
      }
    })
  }
}

//================================
//=====STOP SESSION FUNCTION======
//================================
export async function cancelUploading(vue) {
  //pause the upload
  vue.upload.abort()

  //show dialog that ask the user if he is sure
  const text = vue.$t('Are you sure you want to stop the uploading') + '?'

  const thenFunc = async () => {
    clearInterval(vue.interval)
    store.commit('SET_IS_IN_MIDDLE_OF_FILTERING', false)
    //clear the operation id
    const res = await api.delete(
      `filter?operationId=${vue.session.operationId}`
    )
    if (res.status !== 200) throw Error('api error')
    //resart the session
    vue.restartSessionClicked()
  }

  const catchFunc = e => {
    // if the error is the response fro mthe server
    if (e === 'api error') return
    vue.upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        vue.upload.resumeFromPreviousUpload(previousUploads[0])
      }

      // Start the upload again
      vue.upload.start()
    })
  }

  confirmDialog(vue, text, 'Stop', 'Cancel', thenFunc, catchFunc)
}
