<template>
  <div>
    <h4 class="light-text mt-1">Directory Structure</h4>

    <!--PATH-->
    <div class="d-flex justify-space-between">
      <v-checkbox
        v-model="route.foldersHierarchy.isPath"
        :disabled="isEditDisabled"
        label="Prepend path:"
        dense
        hide-details
        class="ma-0 pa-0"
        style="min-width: 140px"
        height="24px"
      ></v-checkbox>
      <v-text-field
        v-model="route.foldersHierarchy.path"
        :disabled="!route.foldersHierarchy.isPath || isEditDisabled"
        :rules="pathRules"
        height="18px"
        class="path-input ma-0 pa-0 mx-2"
        style="margin-top: 3px !important"
        maxlength="50"
        hide-details
      ></v-text-field>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon dense v-on="on">mdi-information-outline</v-icon>
        </template>
        <span v-html="forbiddenChars"> </span>
      </v-tooltip>
    </div>

    <!--SENDER EMAIL-->
    <v-checkbox
      v-model="route.foldersHierarchy.isSenderEmail"
      :disabled="isEditDisabled"
      label="Sender email"
      dense
      class="ma-0 pa-0"
      hide-details
      height="20px"
    ></v-checkbox>

    <div class="d-flex light-text">
      <!--IS SESSION-->
      <v-checkbox
        v-model="route.foldersHierarchy.isSession"
        :disabled="isEditDisabled"
        dense
        class="pa-0 ma-0 mt-1"
        hide-details
        label="Session (Timestamp)"
        height="20px"
      ></v-checkbox>
      <TimezoneOffset
      class="ml-1"
        :currentTimezoneOffset="route.foldersHierarchy.timezoneOffsetInMinutes"
        :disabled="!route.foldersHierarchy.isPath || isEditDisabled"
        label="Timezone offset"
        @offset-type-changed="offsetTypeChanged"
      />
      <!-- <div v-if="route.foldersHierarchy.isSession" class="d-flex">
        <span class="ml-1 mt-1">Timezone offset:</span>
        <v-text-field
          v-model="timezoneOffsetInputValue"
          :disabled="!route.foldersHierarchy.isPath || isEditDisabled"
          height="18px"
          class="mw-30 ma-0 pa-0 mx-2 offset-ammount"
          style="margin-top: 6px !important"
          type="number"
          hide-details
          @input="offsetTypeChanged"
        >
        
      </v-text-field>
        <v-select
          v-model="offsetType"
          @input="offsetTypeChanged"
          class="pa-0 mw-100"
          style="margin-top: 5px !important;"
          height="16px"
          hide-details
          :items="[
            { text: 'Hours', value: 60 },
            { text: 'Minutes', value: 1 },
          ]"
        ></v-select>
      </div> -->
    </div>
  </div>
</template>

<script>
import { isFolderNameValid } from '@/utils'
import TimezoneOffset from '@/components/BaseComponents/CasualCommonComponents/TimezoneOffset.vue'

export default {
  name: 'DirectoryStructure',
  data() {
    return {
      forbiddenChars: `Forbidden charts: > , < , : , " , | , ? , * <br/> Use '\\' to create folder hierarchy: A\\B`,
      offsetType: 60,
      timezoneOffsetInputValue: 0,
    }
  },
  props: {
    isEditDisabled: Boolean,
    route: Object,
  },
  components: {
    TimezoneOffset,
  },
  computed: {
    pathRules(value) {
      return [
        value => !!value || value === '' || 'Required',
        value => isFolderNameValid(value) || 'Path is not valid',
      ]
    },
  },
  methods: {
    offsetTypeChanged(val) {
      this.route.foldersHierarchy.timezoneOffsetInMinutes = val
    },
  },
  created() {
    if (this.route.foldersHierarchy.timezoneOffsetInMinutes % 60 === 0) {
      console.log('hi 60')
      this.offsetType = 60
      this.timezoneOffsetInputValue =
        this.route.foldersHierarchy.timezoneOffsetInMinutes / 60
    } else {
      console.log('hi 1')
      this.offsetType = 1
      this.timezoneOffsetInputValue =
        this.route.foldersHierarchy.timezoneOffsetInMinutes
    }
  },
}
</script>

<style scoped src="./DirectoryStructure.css"></style>
