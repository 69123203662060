<template>
  <div>
    <!--SUBJECT-->
    <v-text-field
      v-model="session.subject"
      :rules="isShowErrors ? subjectRules : []"
      :disabled="getIsInMiddleOfFilteingData"
      :label="
        interactiveInterfaceFields.subjectFieldText === 'Subject'
          ? $t('Subject')
          : $t('Request Number')
      "
      outlined
      dense
      maxlength="40"
      counter
    >
      <template v-slot:append>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information-outline</v-icon>
          </template>
          <span v-html="forbiddenChars + ' ' + $t('are forbidden')"> </span>
        </v-tooltip>
      </template>

      <template v-slot:counter="{ props }">
        <span :class="[props.value < 37 && 'op-0']" class="red--text">
          {{ ` ${props.value} / ${props.max}` }}</span
        >
      </template>
    </v-text-field>

    <!--MESSAGE-->
    <v-textarea
      v-model="session.message"
      outlined
      :disabled="getIsInMiddleOfFilteingData"
      :label="
        $t(
          interactiveInterfaceFields.messageFieldText === 'Message'
            ? 'Message'
            : 'Sender Details'
        )
      "
      rows="3"
      no-resize
      hide-details
    ></v-textarea>
  </div>
</template>

<script>
import { isFolderNameValid } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SubjectMessage',

  data() {
    return {
      isSubjectError: false,
      forbiddenChars: '> , < , : , " , | , ? , *',
      isShowErrors: false,
    }
  },

  watch: {
    'session.subject': function (nVal, oVal) {
      if (nVal.length === 0 && nVal !== oVal) this.isShowErrors = false
      else this.isShowErrors = true
    },
  },

  computed: {
    ...mapGetters([
      'getSessionData',
      'getFilesSendTo',
      'interactiveInterfaceFields',
      'getIsInMiddleOfFilteingData',
    ]),
    session() {
      return this.getSessionData
    },

    subjectRules() {
      return [
        v => {
          if (isFolderNameValid(v)) return true
          else {
            if (this.interactiveInterfaceFields.subjectFieldText === 'Subject')
              return this.$t('Subject is invalid')
            else return this.$t('Request number is invalid')
          }
        },
        v => {
          if (this.session.subject) return true
          else {
            if (this.interactiveInterfaceFields.subjectFieldText === 'Subject')
              return this.$t('Subject is required')
            else return this.$t('Request number is required')
          }
        },
      ]
    },
  },

  methods: {
    ...mapMutations([]),
  },
}
</script>
