<template>
  <div class="send-email-casual-wrapper">
    <v-card>
      <v-card-title class="text-center bg-grey fs-1-5r card-title">
        {{ computedTitle }}
      </v-card-title>

      <v-card-text>
        <v-textarea
          v-model="sendMessageToCasual"
          :label="$t('Message (Optional)')"
          outlined
          dense
          rows="5"
          multi-line
          class="mt-2"
          hide-details
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <Button :text="$t('Send')" :clickAction="sendEmailClicked" />
        <Button
          :clickAction="closeDialog"
          :text="$t('Cancel')"
          btnType="grey"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { api } from '@/config'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SendEmailToCasualPopUp',
  data() {
    return {
      dialog: true,
      sendMessageToCasual: null,
    }
  },
  props: {
    closeDialog: Function,
    casualUser: Object,
  },
  components: { Button },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isInMiddleOfFiltering',
      'isDarkMode',
      'loggedUser',
      'language',
    ]),

    computedTitle() {
      const isHe = this.language === 'he'
      return (
        this.$t('Send Link To') +
        (isHe ? '' : ' ') +
        this.casualUser.name +
        ' ' +
        this.$t('To Receive Files')
      )
    },
  },

  methods: {
    ...mapMutations(['SET_PROCESSING', 'SET_NOTIFICATION']),
    async sendEmailClicked() {
      try {
        this.SET_PROCESSING(true)
        const res = await api.post('casuals/send-link-for-sending', {
          email: this.casualUser.email,
          message: this.sendMessageToCasual,
        })

        if (res.status !== 200) throw Error

        this.SET_NOTIFICATION({
          type: 'success',
          text: this.$t('Email Sent Successfully'),
        })
        this.closeDialog()
      } catch (error) {
        console.log('error', error)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.send-email-casual-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
