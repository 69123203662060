var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex align-center my-2"},[_c('span',{staticClass:"light-text w-370",class:[
        (_vm.organization.status !== 0 ||
          !_vm.organization.casualUsersDefinitions.isActive) &&
          'low-op-disabeld',
      ]},[_vm._v("Delete files that are sent to guest users after:")]),_c('v-text-field',{staticClass:"mx-3 my-0 pa-0 mw-30 centered-input",attrs:{"disabled":_vm.organization.status !== 0 ||
        _vm.fileLifeTimeIn.fileDeleteAfterNumber === null,"error":_vm.isFileLifeTimeInError,"height":"16px","type":"number","hide-details":""},on:{"input":_vm.fileLifeTimeInChanged},model:{value:(_vm.fileLifeTimeIn.fileDeleteAfterNumber),callback:function ($$v) {_vm.$set(_vm.fileLifeTimeIn, "fileDeleteAfterNumber", $$v)},expression:"fileLifeTimeIn.fileDeleteAfterNumber"}}),_c('v-select',{staticClass:"ma-0 pa-0 mw-80",attrs:{"disabled":_vm.organization.status !== 0,"error":_vm.isFileLifeTimeInError,"height":"16px","hide-details":"","items":_vm.computedFileLifeTimeItems},on:{"input":_vm.fileLifeTimeInChanged},model:{value:(_vm.fileLifeTimeIn.fileDeleteAfterType),callback:function ($$v) {_vm.$set(_vm.fileLifeTimeIn, "fileDeleteAfterType", $$v)},expression:"fileLifeTimeIn.fileDeleteAfterType"}})],1),_c('div',{staticClass:"d-flex align-center my-2"},[_c('span',{staticClass:"light-text w-370",class:[
        (_vm.organization.status !== 0 ||
          !_vm.organization.casualUsersDefinitions.isActive) &&
          'low-op-disabeld',
      ]},[_vm._v("Delete files that are received from guest users after:")]),_c('v-text-field',{staticClass:"mx-3 my-0 pa-0 mw-30 centered-input",attrs:{"disabled":_vm.organization.status !== 0 ||
        _vm.fileLifeTimeOut.fileDeleteAfterNumber === null,"error":_vm.isFileLifeTimeOutError,"height":"16px","type":"number","hide-details":""},on:{"input":_vm.fileLifeTimeOutChanged},model:{value:(_vm.fileLifeTimeOut.fileDeleteAfterNumber),callback:function ($$v) {_vm.$set(_vm.fileLifeTimeOut, "fileDeleteAfterNumber", $$v)},expression:"fileLifeTimeOut.fileDeleteAfterNumber"}}),_c('v-select',{staticClass:"ma-0 pa-0 mw-80",attrs:{"disabled":_vm.organization.status !== 0,"error":_vm.isFileLifeTimeOutError,"height":"16px","hide-details":"","items":_vm.computedFileLifeTimeItems},on:{"input":_vm.fileLifeTimeOutChanged},model:{value:(_vm.fileLifeTimeOut.fileDeleteAfterType),callback:function ($$v) {_vm.$set(_vm.fileLifeTimeOut, "fileDeleteAfterType", $$v)},expression:"fileLifeTimeOut.fileDeleteAfterType"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }