<template>
  <div class="website-interface">
    <div
      v-if="isDataLoaded"
      class="fill-height d-flex flex-column"
      :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
    >
      <!--DOWNLOAD UPLOAD TABS-->
      <DownUpTabs
        :isShowUploading="isShowUploading"
        :isShowDownloading="isShowDownloading"
        :tab="tab"
        :setTab="v => (tab = v)"
      />

      <!--NEW !!!!!!!!!!!!-->
      <div
        style="height: calc(100% - 60px); margin-top: 4px"
        v-if="screenType === 'new'"
      >
        <!--UPLOADING-->
        <Uploading
          v-if="tab === 'uploading'"
          :userActiveSourceRoutes="userActiveSourceRoutes"
        />

        <!--DOWNLOADING-->
        <Downloading v-if="tab === 'downloading'" />
      </div>

      <div
        v-else-if="isLoggedIn"
        :class="[screenType === 'desktop' && 'd-flex']"
        class="screen-sizes-wrapper"
      >
        <!--PHONE WIDTH SMALLER THAN 768PX-->
        <WebsiteInterfaceMobile
          v-if="screenType === 'mobile'"
          :tab="tab"
          :userActiveSourceRoutes="userActiveSourceRoutes"
          :userCameFromEmailPath="userCameFromEmailPath"
          :setTab="v => (tab = v)"
        />

        <!--TABLET WIDTH 768PX - 1024PX -->
        <WebsiteInterfaceTablet
          v-if="screenType === 'tablet'"
          :tab="tab"
          :userActiveSourceRoutes="userActiveSourceRoutes"
          :userCameFromEmailPath="userCameFromEmailPath"
          :setTab="v => (tab = v)"
        />

        <!--SMALL SCREEN WIDTH 1024PX - 1680PX-->
        <WebsiteInterfaceDesktop
          v-if="screenType === 'desktop'"
          :tab="tab"
          :userActiveSourceRoutes="userActiveSourceRoutes"
          :userCameFromEmailPath="userCameFromEmailPath"
          :setTab="v => (tab = v)"
        />
      </div>
    </div>

    <div v-else></div>

    <!--TWO STEP FACTOR IF THIS IS CASUAL USER -->
    <TwoStepFactorDialog
      v-if="isShowTwoStepScreen"
      :casualUserGuid="casualUserGuid"
      :twoStepEnded="twoStepEnded"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

//Functions for this component
import {
  loginAsCasual,
  showRegisteredWebsiteInterface,
} from './WebsiteInterface'

//Childrens
import TwoStepFactorDialog from '@/components/WebsiteInterface/TwoStepFactorDialog/TwoStepFactorDialog.vue'
import WebsiteInterfaceMobile from '@/components/WebsiteInterface/WebsiteInterfaceMobile/WebsiteInterfaceMobile.vue'
import WebsiteInterfaceTablet from '@/components/WebsiteInterface/WebsiteInterfaceTablet/WebsiteInterfaceTablet.vue'
import DownUpTabs from '@/components/WebsiteInterface/DownUpTabs/DownUpTabs.vue'
import WebsiteInterfaceDesktop from '@/components/WebsiteInterface/WebsiteInterfaceDesktop/WebsiteInterfaceDesktop.vue'
import Uploading from '@/components/WebsiteInterface/Uploading/Uploading.vue'
import Downloading from '@/components/WebsiteInterface/Downloading/Downloading.vue'
import { alertDialog } from '@/utils'

export default {
  name: 'WebsiteInterface',
  data() {
    return {
      isDataLoaded: false,
      userActiveSourceRoutes: [],
      tab: null,
      isShowUploading: true,
      isShowDownloading: true,
      isShowTwoStepScreen: false,
      casualUserGuid: '',
      userCameFromEmailPath: null,
    }
  },
  components: {
    TwoStepFactorDialog,
    WebsiteInterfaceMobile,
    WebsiteInterfaceTablet,
    DownUpTabs,
    WebsiteInterfaceDesktop,
    Uploading,
    Downloading,
  },
  computed: {
    ...mapGetters(['language', 'accessibility', 'loggedUser', 'isLoggedIn']),
    screenType() {
      const { width, height } = this.$vuetify.breakpoint

      // if (window.location.host === 'localhost:8080') return 'new'
      //small heights
      if (this.accessibility.fontSize > 1 && height < 670) return 'mobile'
      else if (height < 590) return 'mobile'
      //widths
      //mobile screen
      else if (width < 768) return 'mobile'
      //tablet screen
      else if (width < 1024 && width >= 768) return 'tablet'
      //tablet
      else return 'desktop'
    },
  },
  methods: {
    ...mapMutations(['SET_LANGUAGE']),
    twoStepEnded(user) {
      this.isShowTwoStepScreen = false

      //if the casual user have only the option to see the downloading
      this.isShowUploading = user.isAllowSend

      //if the casual user have the option to see only uploading
      this.isShowDownloading = user.isAllowReceive

      if (!this.isShowUploading) this.tab = 'downloading'

      this.isDataLoaded = true
    },
  },

  async created() {
    // get the casual user data by token and set it as logged user
    const { guid } = this.$route.query

    //if there is a key that says that this is CASUAL USER and log in as a user
    if (guid) return await loginAsCasual(this, guid)
    //if there is no key this is REGISTERED USER
    else {
      await showRegisteredWebsiteInterface(this)
      setTimeout(async () => {
        if (!this.isLoggedIn) {
          alertDialog(this, this.$t('Log in to continue.'))
          return this.$router.push('/')
        }
      }, 100)
    }

    this.isDataLoaded = true

    const language =
      this.loggedUser.language ||
      localStorage.getItem('dot-engines-language') ||
      'en'

    this.SET_LANGUAGE(language)
    this.$i18n.i18next.changeLanguage(language)
  },
}
</script>

<style scoped src="./WebsiteInterface.css"></style>

<style src="./WebsiteInterfaceUnScoped.css"></style>
