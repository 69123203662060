<template>
  <!--IF THIS IS IN MIDDLE OF SESSION SHOW PROGRESS-->
  <div
    v-if="isInMiddleOfFiltering"
    :class="[
      isShowUploadProgress && 'session-details-progress-open',
      $vuetify.breakpoint.width < 850 && 'f-12',
    ]"
    tabindex="0"
    class="wrapper-1 ma-2 session-details fill-width bg-white py-1"
  >
    <!--TOP LINE-->
    <div class="d-flex align-center justify-space-between px-2 fill-height">
      <!--BUTTON TO OPEN / CLOSE PROGRESS-->
      <v-icon @click="isShowUploadProgress = !isShowUploadProgress" large
        >mdi-chevron-{{ isShowUploadProgress ? 'down' : 'right' }}</v-icon
      >

      <!--DATA ABOUT RUNNING SESSION-->
      <div class="d-flex fs-1-25r">
        <!--FILES UPLOADED-->
        <span class="mx-2">
          Uploaded: {{ sessionFiles.progress }} / {{ sessionFiles.total }} files
        </span>

        <v-divider vertical class="mx-2" />

        <!--UPLOAD BYTES-->
        <span class="mx-2">
          Sent: {{ computedSessionSize | bytesToSize }} /
          {{ sessionSize.total | bytesToSize }}
        </span>
      </div>

      <!--CANCEL BUTTON-->
      <v-icon
        @click="stopSessionClicked"
        color="red"
        large
        aria-label="stop filtering"
        >mdi-close-circle-outline</v-icon
      >
    </div>

    <!--ADVANCED PROGRESS-->
    <Transition name="hide">
      <div v-show="isShowUploadProgress" class="fill-width bg-white wrapper-1">
        <UploadProgress
          :filteringProgress="filteringProgress"
          :timer="timer"
          :session="session"
        />
      </div>
    </Transition>
  </div>

  <!--FILES IN THE TABLE -->
  <div
    v-else
    class="wrapper-1 ma-2 d-flex align-center justify-center session-details py-2 fill-width bg-white fs-1-25r"
    tabindex="0"
  >
    <!--TOTAL FILES-->
    <span class="mx-2"> Total files: {{ session.filesData.length }} </span>

    <v-divider vertical class="mx-2" />

    <!--SIZE OF THE FILES-->
    <span class="mx-2">
      Total size: {{ session.totalSize | bytesToSize }}
    </span>
  </div>
</template>

<script>
import UploadProgress from '@/components/WebsiteInterface/CommonComponents/UploadProgress/UploadProgress.vue'
import { bytesToSize } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'SessionDetails',
  data() {
    return {
      isShowUploadProgress: false,
    }
  },
  props: {
    stopSessionClicked: Function,
    sessionFiles: Object,
    sessionSize: Object,
    session: Object,
    curFileUploaded: Object,
    filteringProgress: Object,
    timer: Number,
  },
  filters: {
    bytesToSize,
  },
  computed: {
    ...mapGetters(['isInMiddleOfFiltering', 'loggedUser']),
    computedSessionSize() {
      return (
        this.sessionSize.progress +
        (this.curFileUploaded ? this.curFileUploaded.file.progress : 0)
      )
    },
  },
  components: { UploadProgress },
}
</script>

<style scoped src="./SessionDetails.css"></style>
