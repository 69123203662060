import { render, staticRenderFns } from "./UploadingDetails.vue?vue&type=template&id=5a2f9a96&scoped=true&"
import script from "./UploadingDetails.vue?vue&type=script&lang=js&"
export * from "./UploadingDetails.vue?vue&type=script&lang=js&"
import style0 from "./UploadingDetails.css?vue&type=style&index=0&id=5a2f9a96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2f9a96",
  null
  
)

export default component.exports