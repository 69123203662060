<template>
  <v-app>
    <!--ERRORS THAT COME FROM THE SERVER-->
    <ServerErrorDialog
      v-if="showErrorNotification"
      :errorNotification="errorNotification"
      :closeDialog="() => (showErrorNotification = false)"
    />

    <!--THE SYSTEM WILL DISCONNECT IN-->
    <InactiveDialog />

    <LoadingScreen :isLoading="isProcessing" aria-label="loading..." />

    <div class="background-image" :style="mainWrapperStyle"></div>
    <div v-if="isDarkMode" class="darkmode-shade-background"></div>
    <div
      v-if="isUiLoaded"
      @dragover.prevent="() => null"
      @drop.prevent="() => null"
      id="main-wrapper"
      class="main-wrapper font-weight-light"
      :class="[
        isAddWrapperSmallSize && 'ml-6vw',
        accessibilityClasses,
        isDarkMode && 'dark-mode',
        'roboto-font',
      ]"
    >
      <Header />
      <router-view />
      <Footer v-if="!isLoggedIn || $route.path.includes('filtering')" />
    </div>

    <!--SNACKBAR - NOTIFICATION-->
    <v-snackbar
      v-if="notification"
      v-model="isShowNotification"
      :color="notification.type"
      :timeout="notification.isShowForever ? -1 : 4000"
    >
      <div class="d-flex align-center pa-1">
        <v-icon
          >mdi-{{
            notification.type === 'success' ? 'checkbox-marked-circle' : 'alert'
          }}</v-icon
        >
        <span class="px-2 f-18">{{ notification.text }}</span>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          @click="isShowNotification = false"
          v-bind="attrs"
          fab
          outlined
          small
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { selectedHost, api } from '@/config'
import Bowser from 'bowser'

//Base components
import Header from '@/components/PageParts/Header/Header.vue'
import Footer from '@/components/PageParts/Footer/Footer.vue'
import Button from '@/components/BaseComponents/Button/Button.vue'
import LoadingScreen from '@/components/BaseComponents/LoadingScreen/LoadingScreen.vue'
import ServerErrorDialog from '@/components/BaseComponents/ServerErrorDialog/ServerErrorDialog.vue'
import InactiveDialog from '@/components/BaseComponents/InactiveDialog/InactiveDialog.vue'

export default {
  name: 'App',
  data() {
    return {
      timer: null,
      isUiLoaded: false,
      showErrorNotification: false,
      isShowNotification: false,
    }
  },

  components: {
    Footer,
    Header,
    Button,
    LoadingScreen,
    ServerErrorDialog,
    InactiveDialog,
  },

  watch: {
    isServerError: function (n, o) {
      if (n !== o) {
        this.showErrorNotification = true
      }
    },
    notification: function (n) {
      if (n) {
        this.isShowNotification = true
      } else {
        this.isShowNotification = false
      }
    },
    isShowNotification: function (n) {
      if (n === false) this.SET_NOTIFICATION(null)
    },
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'loggedUser',
      'isProcessing',
      'errorNotification',
      'isServerError',
      'themeSettings',
      'currentOrganization',
      'notification',
      'isInMiddleOfFiltering',
      'accessibility',
      'isDarkMode',
    ]),
    mainWrapperStyle() {
      return `background: url(${
        this.themeSettings.backgroundInterface
          ? this.themeSettings.backgroundUrl
          : `${selectedHost}/api/images/0/background`
      });`
    },
    isAddWrapperSmallSize() {
      if (this.$vuetify.breakpoint.width >= 1024) return false
      if (!this.$route.path.includes('management')) return false
      return true
    },
    accessibilityClasses() {
      const app = document.getElementById('app')
      const arr = []
      //strong colors
      if (this.accessibility.isStrongColors) arr.push('strong-colors')

      //font sizes
      if (this.accessibility.fontSize === 1)
        document.children[0].style.fontSize = '14px'
      else if (this.accessibility.fontSize === 2)
        document.children[0].style.fontSize = '16px'
      else if (this.accessibility.fontSize === 3)
        document.children[0].style.fontSize = '18px'

      arr.push(`font-size-${this.accessibility.fontSize}`)

      //font - types
      //no font
      if (this.accessibility.isRegularFont) {
        const mainWrapper = document.getElementById('main-wrapper')
        app.style.fontFamily = 'none'
        app.classList.remove('roboto-font')
        app.classList.add('unset-font')
        if (mainWrapper) {
          mainWrapper.classList.remove('roboto-font')
          mainWrapper.classList.add('unset-font')
        }
      }
      //roboto
      else {
        const mainWrapper = document.getElementById('main-wrapper')
        app.style.fontFamily = 'Roboto, sans-serif'
        app.classList.remove('unset-font')
        app.classList.add('roboto-font')
        if (mainWrapper) {
          mainWrapper.classList.add('roboto-font')
          mainWrapper.classList.remove('unset-font')
        }
      }

      //add cursor
      app.classList.remove('big-cursor')
      app.classList.remove('big-black-cursor')

      //white
      if (this.accessibility.mouseType === 'white')
        app.classList.add('big-cursor')
      //black
      else if (this.accessibility.mouseType === 'black')
        app.classList.add('big-black-cursor')

      //animations
      //with
      if (this.accessibility.isAnimation)
        app.classList.remove('disable-animations')
      //without animations
      else app.classList.add('disable-animations')
      return arr
    },
  },

  methods: {
    ...mapActions(['LOG_OUT', 'GET_THEME', 'AUTH', 'GET_SINGLE_ORGANIZATION']),
    ...mapMutations([
      'SET_PROCESSING',
      'SET_NOTIFICATION',
      'SET_USER_CONNECTION_TYPE',
      'SET_IS_DARKMODE',
    ]),
  },

  async created() {
    // if (
    //   window.location.host === 'localhost:8080' ||
    //   window.location.host === 'localhost:8081'
    // )
    //   this.SET_IS_DARKMODE(true)

    //make the site title
    if (window.location.hostname === 'bestcdr.com') document.title = 'Best CDR'
    else if (window.location.hostname === 'cyber-leader.com')
      document.title = 'Cyber Leader'
    else document.title = 'dotEngines'

    //get the user system and store it
    const connection = Bowser.parse(window.navigator.userAgent)
    this.SET_USER_CONNECTION_TYPE(connection.platform.type)

    try {
      this.SET_PROCESSING(true)

      //getting the host theme from suffix
      const suffix = this.$route.query.suffix
      await this.GET_THEME({
        suffix,
      })
      
      // for refreshers and "back"
      const user = sessionStorage.getItem('user')
      if (user) {
        let cachedUser = JSON.parse(user)
        if (cachedUser.token) {
          this.$http.defaults.headers.common.Authorization =
          'Bearer ' + cachedUser.token
          api.defaults.headers.common['Authorization'] = `Bearer ${cachedUser.token}`
        // if (cachedUser.role === 'SuperAdmin' || cachedUser.role === 'Admin') {
        //   await this.GET_SINGLE_ORGANIZATION(cachedUser?.organizationId)
        // }
        await this.GET_THEME({ id: cachedUser?.organizationId })
          // const refreshRes = await api.get('auth/refresh-token') // import api when uncommented!!
          // if (refreshRes.status !== 200) throw Error
          // cachedUser.token = refreshRes.data.token
          // sessionStorage.setItem(
          //   'user',
          //   JSON.stringify({
          //     ...cachedUser,
          //   })
          // )
          await this.AUTH()
        }
      }
    } catch (error) {
      console.log(error)
      await this.GET_THEME()
    } finally {
      this.SET_PROCESSING(false)
    }
    this.isUiLoaded = true
  },
}
</script>

<style lang="scss" src="./App.scss"></style>
