<template>
  <div
    class="wrapper-1 uploading-details ma-2 bg-white"
    :class="[
      isShowUploadingDetails
        ? 'uploading-details-open'
        : 'uploading-details-close',

      isShowUploadingDetails && session.filesData.length && 'wrapper-hard',
    ]"
  >
    <!--UPLOADING DETAILS-->
    <UploadingDetailsHeader
      :isShowUploadingDetails="isShowUploadingDetails"
      :toggleIsShowUpDetails="
        v => (isShowUploadingDetails = !isShowUploadingDetails)
      "
      :session="session"
    />

    <!--ALL FIELDS-->
    <Transition name="hide">
      <v-tabs
        v-model="activeTab.tab"
        v-show="isShowUploadingDetails"
        class="px-2 pb-2"
      >
        <div v-if="isShowTabs" class="mx-auto d-flex">
          <v-tab href="#regular">{{ $t('Registered') }}</v-tab>
          <v-tab href="#casual">{{ $t('Guest') }}</v-tab>
        </div>

        <v-tab-item value="regular" class="my-2 pa-2 mb-6">
          <RegularSend
            :session="session"
            :userActiveSourceRoutes="userActiveSourceRoutes"
            :restartSessionClicked="restartSessionClicked"
            :lightSessionRestart="lightSessionRestart"
          />
        </v-tab-item>

        <v-tab-item value="casual" class="mt-2 mb-6">
          <CasualSend :session="session" />
        </v-tab-item>
      </v-tabs>
    </Transition>

    <!--START BUTTON-->
    <div
      v-if="isShowStartButton"
      class="start-restart-button"
      @click="startFilteringButtonClicked"
      :class="[language === 'he' && 'start-restart-button-rtl']"
    >
      <Button
        :text="$t('Start')"
        :clickAction="startFilteringButtonClicked"
        :disabled="!!filteringErrMessage"
        width="150"
      />
    </div>

    <!--RESTART BUTTON-->
    <Button
      v-if="isShowRestartButton"
      :clickAction="restartSessionClicked"
      :text="$t('Additional Upload')"
      icon="mdi-cached"
      class="start-restart-button"
      width="180"
    />
  </div>
</template>

<script>
//Functions to check if upload is okay
import { checkUploadingDetails } from '@/components/WebsiteInterface/Websiteinterface'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'
import FileDirButtons from '@/components/WebsiteInterface/CommonComponents/FileDirButtons/FileDirButtons.vue'
import UploadingDetailsFields from '@/components/WebsiteInterface/CommonComponents/UploadingDetailsFields/UploadingDetailsFields.vue'
import CasualSend from '@/components/WebsiteInterface/CommonComponents/CasualSend/CasualSend.vue'
import RegularSend from '@/components/WebsiteInterface/CommonComponents/RegularSend.vue'

//Childrens
import UploadingDetailsHeader from './Childrens/UploadingDetailsHeader/UploadingDetailsHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UploadingDetails',
  data() {
    return {
      isShowUploadingDetails: true,
      filteringErrMessage: 'a',
    }
  },
  props: {
    session: Object,
    statusResults: Object,
    userActiveSourceRoutes: Array,
    startTabletFiltering: Function,
    restartSessionClicked: Function,
    errors: Object,
    lightSessionRestart: Function,
    activeTab: Object,
  },

  components: {
    Button,
    FileDirButtons,
    UploadingDetailsHeader,
    UploadingDetailsFields,
    RegularSend,
    CasualSend,
  },

  watch: {
    'session.filesData': {
      handler: function (n) {
        if (n) {
          if (!this.isInMiddleOfFiltering) this.isShowUploadingDetails = false
        }

        if (n.length === 0) this.isShowUploadingDetails = true
      },
      deep: true,
    },
    'session.operationId': {
      handler: function (n) {
        if (n === '') this.isShowUploadingDetails = true
      },
      deep: true,
    },

    session: {
      handler: function (n) {
        const res = checkUploadingDetails(this, 'tablet', true)
        if (res === true) this.filteringErrMessage = ''
      },
      deep: true,
    },

    errors: {
      handler: function (n) {
        //this will restart the errors after 2.5 sec
        for (let err of Object.keys(this.errors)) {
          if (n[err]) setTimeout(() => (this.errors[err] = false), 2500)
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'isInMiddleOfFiltering',
      'language',
      'interactiveInterfaceFields',
      'loggedUser',
    ]),
    isShowStartButton() {
      //if there is operation id then
      if (this.statusResults.session.operationId) return false
      if (this.isInMiddleOfFiltering) return false
      return true
    },
    isShowTabs() {
      const isShowCasuals = this.loggedUser.isAllowCasuals
      const isShowRegistererd = this.userActiveSourceRoutes.length > 0
      if (!isShowCasuals || !isShowRegistererd) {
        const el = document.getElementsByClassName('v-slide-group')
        // if (el && el.length && el[1]) el[1].style.display = 'none'
        return false
      }

      return true
    },
    isShowRestartButton() {
      //if there is operation id then
      if (this.statusResults.session.operationId) return true
      return false
    },
  },
  methods: {
    startFilteringButtonClicked() {
      const isUploadingDetailsOK = checkUploadingDetails(this, 'tablet')

      //if can start filtering
      if (isUploadingDetailsOK === true) {
        this.isShowUploadingDetails = false
        this.startTabletFiltering()
      }

      //if cannot start filtering
      else {
        this.isShowUploadingDetails = true
      }
    },
  },
}
</script>

<style scoped src="./UploadingDetails.css"></style>
